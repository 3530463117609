export default {
  APP_NAME: 'SNS 매트릭스 관리',
  LOGIN: '로그인',
  LOGIN_NAME: '로그인 이름',
  PASSWORD: '암호화',
  USERNAME_INPUT_TIP: '사용자 이름을 입력하세요.',
  PASSWORD_INPUT_TIP: '비밀번호를 입력하세요.',
  VERIFICATION_CODE_INPUT_TIP: '인증 코드를 입력하세요.',
  ERROR_USER_PWD_TIP: '아이디 또는 비밀번호가 틀렸습니다.',

  EXIT: '로그아웃',
  MULTILINGUAL: '언어',
  CHANGE_PASSWORD: '비밀번호 변경',
  CONFIRM_PASSWORD: '비밀번호 확인',
  NEW_PASSWORD: '새 비밀번호',
  OLD_PASSWORD: '원래 비밀번호',
  PWD_RULE_TIP: '비밀번호는 8자 이상이어야 하며 최소 두 가지 규칙과 일치하는 숫자, 문자 또는 특수 문자를 포함해야 합니다.',
  CONFRIM_PWD_NOT_SAME_TIP: '일관성 없는 비밀번호 입력!',
  PAGINATION_INFO_TIP: '총 {total} 중, 현재 {start} - {end} 입니다.',
  ACTION_SUBMIT: '제출',
  ACTION_DELETE: '지우기',
  ACTION_REMOVE: '삭제',
  ACTION_SAVE: '저장',
  ACTION_SAVA_DRAFT: '초안으로 저장',
  ACTION_SUBMIT_PUBLISH: '게시를 위해 제출',
  ACTION_DOWNLOAD: '다운로드',
  ACTION_DISABLE: '비활성화',
  ACTION_ENABLE: '활성화',
  ACTION_DOWNLOAD_DATA: '데이터 다운로드',
  ACTION_VIEW: '보기',
  ACTION_EDIT: '편집',
  ACTION_OPERATE: 'rig',
  ACTION_SEARCH: '검색',
  ACTION_NEW: '생성',
  ACTION_CANCEL: '취소',
  ACTION_ADD: '추가',
  LOGIN_PWD: '로그인 비밀번호',
  COPY: '사본 만들기',

  STATUS: '상태',

  OPERATION_SUCCESS: '설정 완료',
  DELETE_SUCCESS: '삭제 완료',
  CREATE_SUCCESS: '생성 완료',
  CHANGE_PASSWORD_SUCCESS: '비밀번호가 성공적으로 변경되었습니다.',
  EDIT_SUCCESS: '편집 완료',
  OPERATION_FAILURE: '설정 불가',
  DELETE_FAILURE: '삭제 불가',
  VALIDATION_EXCEIPTION: '인증 만료',
  NO_PREMISSION: '현재 이 페이지를 설정할 권한이 없습니다',
  NO_DATA: '사용 가능한 데이터 없음',
  CREATE_TIME: '생성 시간',
  CREATOR: '설립자',

  CONFIRM_DELETE_NAME_TIP: '{name}이 삭제되었는지 확인하시겠습니까?',

  //============组件========
  NUM_MONTH: '{num} 개월',
  NUM_WEEK: '{num} 주',
  NUM_DAY: '{num} 일',
  NUM_HOUR: '{num} 시간',
  NUM_MINUTE: '{num} 분',
  NUM_SECOND: '{num} 초',
  JUST_NOW: '방금',
  DATE_BEFORE: '전',
  DATE_AFTER: '후',
  ORGANIZATIONAL_ACCOUNT: '조직 계정 번호',
  PLAY_VIDEO: '동영상 재생',
  CLICK_TO_PC_WATCH_VIDEO: '동영상을 보려면 PC 버전을 클릭하세요.',
  CLICK_TO_WATCH: '클릭 후 재생',
  ACCOUNT: '계정',
  APPLY: '응용',
  SELECTED_NUM_COLUMNS: '{num} 열 선택됨',
  RESET_TO_DEFAULT: '기본값 복원',
  CLEAR_EMPTY: '비우기',
  ABOVE_LIST_CANNOT_BE_MODIFIED: '위의 열은 수정할 수 없습니다.',
  ERROR_LOADING_COMPONENT_REFRESH_PAGE: '구성 요소를 로드하는 중 오류가 발생했습니다. 페이지를 새로고침한 후 다시 시도하세요.',
  PLEASE_SELECT_AFFILIATED_ORGANIZATION: '소속 선택',
  CUMULATIVE: '누적',
  AUTHORIZATION_SUCCESSFUL: '인증 성공',
  YESTERDAY: '어제',
  PLAY_HISTORY_LIVE_VIDEO: '이전 라이브 재생',
  AUTO_LOCATE_CITY: '위치 자동 설정',
  MANUALLY_FILL_IN_REGION: '지역 수동 입력',
  SOURCE_PAGE: '소스 페이지',
  COMPONENT_TYPE: '구성 요소 유형',
  SERVICE_CLUE_SOURCE: '서비스 번호 리드 소스',
  CALL_HISTORY: '통화 기록',
  LEAD_PROVINCE_ORIGIN: '리드 사용자의 소속 지역',
  LEAD_CITY_ORIGIN: '리드 사용자가 속한 도시',
  ADVERTISING_GROUP_ID: '광고 그룹 ID',
  LANDING_PAGE_ID: '랜딩 페이지 ID',
  ADVERTINSING_CREATIVAITY_ID: '광고 크리에이티브 ID',
  ADVERTINSING_CREATIVAITY: '크리에이티브 광고',
  ADVERTINSING_GROUP_NAME: '광고 그룹 이름',
  LANDINGPAGE_NAME: '랜딩 페이지 이름',
  PURPOSE_INFO: '의도 정보',
  INTENDED_GOODS: '의도된 상품',
  INREND_PROVINCE_NAME: '리드 사용자의 의도된 지역',
  INREND_CITY_NAME: '리드 사용자의 의도 도시',
  STORE_PROVINCE: '상점 정보-주',
  STORE_CITY: '매장 정보-도시',
  STORE_NUMBER: '스토어 정보 - 스토어 코드',
  STORE_INFO_NAME: '스토어 정보-스토어 이름',
  FORM_ID: '양식 ID',
  FORM_NAME: '양식 이름',
  PAY_ORDER_NO: '결제 양식 주문 번호',
  PAY_ORDER_STATE_DES: '결제 양식 주문 상태 설명',
  COUPON_ID: '바우처 구성 요소 - 바우처 ID',
  COUPON_NAME: '카드 쿠폰 구성 요소 - 카드 쿠폰 이름',
  COUPON_NUMBER: '바우처 구성 요소 - 바우처 코드',
  COUPPON_PHONE: '바우처 구성 요소 - 휴대폰 번호',
  COUPON_EXPIRE: '카드 바우처 구성 요소 - 만료 시간',

  NUMBER_OF_LEADS: '리드 수',
  ADVERTISEMENT_INFORMATION: '광고 정보',
  ADVERTISER_ID: '광고주 ID',
  ADVERTISER_NAME: '광고주 이름',
  AD_CAMPAIGN_ID: '광고 프로그램 ID',
  AD_CAMPAIGN_NAME: '광고 프로그램 이름',
  CREATIVE_ID: '크리에이티브 ID',
  ADVERTISEMENT_ID: '광고 ID',
  ADVERTISEMENT_NAME: '광고 이름',
  COMPANY_INFORMATION: '기업 정보',
  OTHER_INFORMATION: '기타 정보',
  LEAD_ID: '리드 ID',
  CAR_INFORMATION: '자동차 정보',
  INTENDED_CAR_SERIES: '구매 의향 차량 시리즈',
  INTENDED_CAR_MODEL: '구매 의향 모델',
  PURCHASE_INTENTION: '자동차 구매 의향',
  OTHER: '기타 정보',
  OPERATOR_MOBILE_NUMBER: '운영자의 휴대폰 번호',
  REGION_AREA: '지역',
  REGION_NAME: '지역 이름',
  DEALER: '딜러',
  DEALER_NAME: '딜러 이름',
  EMPLOYEE: '직원',
  EMPLOYEE_NAME: '직원 성명',
  STORE: '지점',
  STORE_INFO: '매장 정보',
  STORE_NAME: '지점 이름',
  STORE_CODE: '지점 코드',
  INFLUENCER: '(틱톡) 파워유저',
  INFLUENCER_NAME: '파워유저 아이디',
  DEALER_CODE: '딜러 코드',
  TIKTOK_ACCOUNT_INFORMATION: '틱톡 계정 정보',
  ACCOUNT_INFORMATION: '계정 정보',
  TIKTOK_NICKNAME: '틱톡 닉네임',
  PLATFORM_TYPE: '플랫폼 유형',
  TOTAL_VIDEOS: '총 동영상 수',
  NEW_FANS: '신규 팔로잉',
  NET_FOLLOW_FANS: '팬 수 순증가',
  NEW_LEADS: '새 단서 추가',
  HOMEPAGE_VISITORS: '홈페이지 방문자 수',
  SHORT_VIDEO: '숏폼',
  NEW_VIDEOS: '신규 영상',
  NEW_PLAY: '신규 재생',
  NEW_LIKES: '신규 좋아요',
  NEW_COMMENTS: '신규 댓글',
  NEW_SHARES: '신규 공유',
  DURATION_MINUTES: '시간(분)',
  AUDIENCE_NUMBER: '시청자 수',
  AVERAGE_STAY_DURATION_MINUTES: '평균 시청시간(분)',
  AVERAGE_STAY_DURATION: '평균 숙박 기간',
  LEAD_GENERATION: '리드',
  LIVE_SCENE_LEAD_NUMBER: '라이브 내 리드 수',
  LIVE_ROOM_FORM_SUBMITTED_LEAD_COUNT: '라이브 중 양식 제출 수',
  ORANGE_SITE_LEAD_COUNT: 'Feiyu 리드 수',
  ORGANIC_TRAFFIC_LEAD_COUNT: '자연 트래픽 리드 수',
  TOTAL_LEADS_COUNT: '리드 총계 (회)',
  VIDEO_LEADS: '영상 내 리드 (회)',
  LIVE_LEADS: '라이브 리드 (회)',
  MESSAGE_LEADS: 'DM 리드 (회)',
  HOMEPAGE_LEADS: '메인페이지 리드 (회)',
  ENTER_MESSAGE_SESSION_NUMBER: 'DM창 입장 수 (명)',
  MESSAGE_SESSION_INITIATOR_NUMBER: 'DM 수신 수 (명)',
  MESSAGE_LEAD_QUANTITY: 'DM 리드 수',
  MESSAGE_LEAD_CONVERSION_RATE: 'DM 리드 전환율',
  ACCEPTED_MESSAGES: '메시지 수신',
  MISSED_MESSAGES: '읽지 않은 메시지',
  THREE_MIN_REPLY_RATE: '3분 내 응답률',
  ADVERTISEMENT: '광고',
  AD_DIRECT_LEAD_QUANTITY: '광고 - 직접 리드 수',
  AD_INDIRECT_LEAD_QUANTITY: '광고 - 간접 리드 수',
  AD_SPEND: '광고 지출',
  AD_LEAD_COST: '리드 코스트',
  DIRECT_LEAD_COST: '직접 리드 코스트',
  AD_IMPRESSIONS: '광고 표시량(회)',
  AD_CLICK_THROUGH_RATE: '광고 클릭률',
  NATIVE_AD_MANAGEMENT_NEW_FANS: '네이티브 광고 비즈니스 - 신규 팔로워 규모',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_SHARES: '네이티브 광고 운영 - 숏폼 공유량',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_COMMENTS: '네이티브 광고 운영 - 짧은 동영상 댓글 볼륨',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_LIKES: '네이티브 광고 운영 - 숏폼 좋아요',
  NATIVE_AD_MANAGEMENT_HOMEPAGE_VISITS: '네이티브 광고 운영 - 페이지 방문자 수',
  NATIVE_AD_MANAGEMENT_ENTER_MESSAGE_PEOPLE: '네이티브 광고 운영 - DM 액세스 수',
  LP_SCREEN_LIVE_WATCH_UV_TOTAL: '실시간 시청자 수',
  LIVE_STREAM_THEME: '라이브 스트리밍 주제',
  EXPOSURE_UV: '버블 노출 횟수',
  EXPOSURE_CLICK_UV: '버블 히트 횟수',
  COLLECTED_LEAD_NUM: '수집된 리드 수',
  EXPOSURE_CONVISION_RATE: '노출 클릭 전환율',
  LIVE_DIRATION: '생방송 시간(분)',
  XHS_ACCOUNT_NICKNAME: '리틀 레드 북 닉네임',
  NEW_IMAGE_TEXT_FANS_COUNT: '새로운 팬을 추가하는 그래픽',
  INCREASED_FANS_NUM_FROM_VIDEO: '동영상으로 새로운 팬 추가',
  INCREASED_FANS_NUM_FROM_NOTE: '새 팬 추가 시 참고 사항',
  IMAGE_TEXT_TOTAL: '총 그래픽 수',
  NOTE_TOTAL: '총 메모',
  XHS_ACCOUNT: '리틀 레드 부커(한자 사전)',
  ADD_PICTURE_TEXT: '새 그래픽 추가',

  ADD_PLAY_FROM_PICTURE_TEXT: '보기용 그래픽 추가',
  ADD_COMMENT_FROM_PICTURE_TEXT: '그래픽 추가 댓글',
  ADD_LIKE_FROM_PICTURE_TEXT: '그래픽에 좋아요 추가',
  ADD_SHARE_FROM_PICTURE_TEXT: '공유를 위한 그래픽 추가',
  ADD_FAV_FROM_PICTURE_TEXT: '컬렉션에 그래픽 추가',
  ADD_RECOMMENDS: '추천 추가',

  TURN_ON_NAVIGATION: '탐색 열기',
  TURN_OFF_NAVIGATION: '탐색 끄기',
  HOME_PAGE: '메인 페이지',
  COPY_SUCCESSFUL: '복사 성공',

  //=============平台=========
  PLATFORM: '테라스',
  PLATFORM_DOUYIN: '지터버그',
  PLATFORM_KUAISHOU: '바이올린',
  PLATFORM_XIHONGSHU: '리틀 레드 북(소셜 네트워킹 웹사이트)',
  PLATFORM_SHIPINGHAO: '동영상 번호',

  //=============菜单==============
  WORKBENCH: '워크 플레이스',
  USER_CENTER: '사용자 센터',
  DATA_VIEW: '데이터 개요',
  DATA_STATISIICS: '데이터 통계',
  PLATFORM_ACCOUNT: '플랫폼 계정',
  LIVE_MANAGE: '라이브 스트리밍 관리',
  LIVE_LIST: '라이브 스트리밍 목록',
  VIDEO_OPERATIONS: '영상 운영',
  WORK_MANAGEMENT: '영상 관리',
  WORK_PUBLISH: '아트웍 릴리스',
  INTERACTIVE_MANAGEMENT: '상호 작용 관리',
  CONVERSATION_MANAGEMENT: '대화 관리',
  SESSION_AGGREGAITION: '대화 집계',
  HISTORICL_CONVERSATION: '대화 히스토리',
  COMMENT_MANAGEMENT: '댓글 관리',
  LIVE_BARRAGE_MANAGEMENT: '라이브 팝업 관리',
  CUSTOMER_ACQUISITION_MANAGEMENT: '고객 확보 관리',
  CLUE_MANAGEMENT: '리드 관리',
  HIEGH_POTENTIAL_USERS: '높은 잠재고객',
  SYSTEM_CONFIGATION: '시스템 구성',
  AUTHORIZED_ACCOUNT: '승인된 계정',
  SEMANTIC_CLASSIFICATION: '의미 카테고리',
  ORGANIZATIONAL_MANAGEMENT: '조직 관리',
  PERMISSION_MANAGE: '권한 관리',
  PERMISSION_USER: '사용자 관리',
  PERMISSION_USERGROUP: '사용자 그룹 관리',
  PERMISSION_ROLE: '역할 관리',

  //=============表单提示==============
  PLEASE_SELECT: '선택해 주세요',
  PLEASE_SELECT_MULTIPLE_NAME: '{name}을 선택해 주세요. (여러 개)',
  PLEASE_SELECT_SINGLE_NAME: '{name}을 선택해 주세요.',
  PLEASE_TYPE: '입력하세요',
  PLEASE_TYPE_NAME: '{name}을 입력하세요.',
  PLEASE_TYPE_NUMBER: '숫자를 입력하세요.',
  BASIC_INFO: '기본 정보',
  CREATE_NAME: '{name} 만들기',
  EDIT_NAME: '{name} 수정',
  VIEW_NAME: '{name} 보기',

  //=============账户==============
  FANS: '팔로잉',
  VIDEO: '영상',
  LIVE: '라이브',
  LIVT_BROADCAST_TOTAL: '총 생방송 횟수',
  TOTAL_NEW_FANS: '신규 팬 수',
  TOTAL_LOST_FANS: '잃어버린 팬 수',
  OVERVIEW: '개괄',
  TREND_ANALYSIS: '트렌드 분석',
  FAN_PORTRAIT: '고객 페르소나',
  USER_ANALYSIS: '사용자 분석',
  GENDER_DISTRIBUTION: '성별 분포',
  AGE_DISTRIBUTION: '연령 분포',
  VIEW_REPORT: '보고서 보기',
  COMMENT_TIME: '댓글 작성 시간',
  NICKNAME: '사용자 닉네임',
  AFFILIATION_ORG: '소속',
  ACCOUNT_NAME: '계정 이름',
  AUTHORIZE_ACCOUNT_NAME: '인증된 계정 이름',
  ACCOUNT_REPORT_AUDIENCE_ACTIVE_TIME: '시청자 활동 시간',
  ACCOUNT_REPORT_PROVINCE_DISTRIBUTION: 'TOP10 소재지 (省)',
  ACCOUNT_REPORT_CROWD_DISTRIBUTION: '8개 유형별 분포 상황',
  REGION_REPORT_DISTRIBUTION_TOP: '지역별 분포 상위 10위',
  INTEREST_REPORT_DISTRIBUTION_TOP: '이자 분배 Top10',
  INTEREST_DISTRIBUTION: '이자 분배',
  ACCESS_DEVICES: '장비 액세스',
  ACTIVE_TIME: '활동 시간',
  SYSTEM_DISTRIBUTION: '시스템 배포',
  DATA_TRANDS: '데이터 트렌드',
  FUNS_SOURCE: '팬 소스',
  PROVINCE_DISTRIBUTION: '지역 분포',
  CITY_DISTRIBUTION: '도시 분포',
  LIVE_ROOM_ID: '라이브 스트리밍 ID',
  LIVE_ROOM_ID_TIP: '숫자를 입력하세요 (쉼표로 구분)',
  LIVE_TIME: '라이브 시작 시간',
  RANKINGS: '순위',
  PROVINCE: '제외',
  PEOPLE_COUNT: '쿼럼',
  TIME_COUNT: '횟수',
  PERCENTAGE: '백분율',
  NOTE: '주로 짧은 스케치로 구성된 문학의 한 유형입니다.',

  //=============交互管理==============
  INTERACTIVE_COMMENT_DATA_TIPS: '각 라이브에는 타사용자 팝업, 규정에 위배되지 않는 팝업만 표시됩니다.',
  BULLET_CONTENT: '팝업 내용',
  KEYWORD_CLASSIFICATION: '키워드 분류',
  KEYWORD_HIT: '적중 키워드',
  PUBLISH_ACCOUNT: '계정',
  ORGANIZATION_TYPE: '조직 유형',
  ACTION_LIVE_REPLAY: '라이브 다시보기',
  ACTION_VIEW_COMMENT: '댓글 보기',
  ACTION_MORE_BARRAGE: '팝업 더보기',
  NO_COMMENT: '댓글 없음',
  COMMENT_COUNT_TIP: '총 {count} 개 댓글',
  DATA_UPDATE_TIME_TIP: '마지막 업데이트 시간 {syncTime}',
  AFFILIATION_PLATFORM: '플랫폼',
  SEND_TIME: '전송 시간',
  ALL: '전체',
  UNCLASSIFIED: '미분류',
  COMMENT_CONTENT: '댓글',
  WORK: '영상',
  WORK_ACCOUNT: '영상 계정',
  CONVERSATION_CARD_TIP: '[카드 메시지] 이 유형의 메시지는 현재 지원되지 않습니다 앱 페이지로 이동하여 확인하세요.',
  CONVERSATION_NOT_SUPPORT_TIP: '이 메시지 유형은 현재 지원되지 않습니다 앱으로 이동하여 확인하세요.',
  CONVERSATION_INFO: '세션 정보',
  CONVERSATION_CURRENT: '현재 세션',
  CONVERSATION_ALL: '모든 세션',
  CONVERSATION_SEARCH_TIP: '세션 콘텐츠 검색',
  TIKTOK_ACCOUNT: '틱톡',
  MATRIX_ACCOUNT_NAME: '매트릭스 계정 이름',
  ADDRESS: '주소',
  USER: '사용자',
  SESSION_RECORDS: '세션 녹화',
  MESSAGE_COUNT: '총 메시지 수',
  SESSION_TIME: '대화 시간',
  SESSION_END_TIME: '대화 종료 시간',
  SESSION_DURATION: '대화 길이',
  SESSION_ACCOUNT: '대화 계정',
  NO_CONVERSATIONS: '대화 없음',
  TOTAL_CONVERSATIONS_COUNT: '총 {count} 개 대화',
  TOTAL_COUNT: '합계 {total}',

  //=============获客管理==============
  SOURCE_TIKTOK_ACCOUNT: '틱톡 소스',
  SOURCE_TIKTOK_NICKNAME: '틱톡 닉네임 소스',
  SHIPINGHAO_NICKNAME: '동영상 번호 닉네임',
  SHIPINGHAO_ACCOUNT: '동영상 번호',
  CLUE: '리드',
  CLUE_TYPE: '리드 유형',
  CLULE_ACCOUNT: '단서 계정',
  INTERACTION_TYPE: '상호작용 유형',
  CLUE_CHANNEL: '리드 채널',
  FLOW_TYPE: '트래픽 유형',
  NAME: '이름',
  CLUE_CRATE_TIME: '리드 생성 시간',
  LAST_ACTION_TIME: '최근 상호작용 시간',
  CUSTOM_COLUMNS: '사용자 지정 열',
  LIVE_BROADCAST_ROOM_BARRAGE: '라이브 팝업',
  VIDEO_COMMENT: '영상 댓글',
  VIEW_DETAIL: '자세히',
  RERA: '지역',
  CLUE_DETAIL_COMMENT_TPL: '{nickName}이 {accountName}을 통해 {mediaName}에 남긴 댓글 - "{title}":"{content}"',
  CLUE_DETAIL_BULLET_TPL: '{nickName}이 {accountName}을 통해 {mediaName}으로 "{content}" 팝업을 보냅니다.',
  CLUE_DETAIL_SESSION_TPL: '{nickName}이 {accountName}을 통해 {mediaName} 비공개 대화를 시작했습니다: "{content}"',
  CLUE_DETAIL_CLUE_TPL: '{nickName}을 {accountName}으로, {mediaName}에서 보존: "{content}"',
  PRIVATE_LETTER: '비공개 편지',
  VIEW_CLUE_DETAIL: '리드 세부 정보 보기',
  TELEPHONE: '전화',
  TRAFFIC_SOURCE: '트래픽 소스',
  INTERACTION_RECORDS: '상호작용 기록',
  INTERACTIVE_TIME: '상호작용 시간',
  INTERACTION_ACCOUNT: '상호작용 아이디',
  CLUE_BASIC_INFO: '리드 정보',
  USER_TIKTOK_ACCOUNT: '고객 틱톡 아이디',
  USER_TIKTOK_NICKNAME: '고객 틱톡 별칭',
  USER_NICKNAME: '사용자 계정 닉네임',
  USER_ACCOUNT: '사용자 계정',
  CUSTOMER_CITY: '고객 도시',
  JOB_CANDIDATES: '게시물 신청',
  CITIES: '지자체',
  CALL_STATUS: '통화 상태',
  USER_INFO: '사용자 정보',

  AGE: '연령',
  GENDER: '성별',
  WECHAT: '위챗',
  BIRTHDAY: '생일',
  CLUE_SOURCE_INFO: '리드 출처',
  IS_FUNDS: '리드 제출 여부',
  FUNDS_YES: '리드 제출됨',
  FUNDS_NO: '리드 미제출',
  CITY_AREA: '지역',
  CLUE_INFO: '토론글 정보',
  LATEST_CLUE: '최신 리드',
  COMMENT_COUNT: '잠재의향 댓글 수',
  NUM: '번호',
  KS_ACCOUNT: '중국에서 가장 인기 있는 뮤직 비디오 게임에서 사용되는 한자 이름, Snapchat',
  KS_NICKNAME: '닉네임',
  COLLECTION_TIME: '수거 시간',
  FILTER_RESULT: '청소 결과',
  CLUE_PREGRESS: '리드 진행 상황',
  TRANSFORM: '변환',

  //============系统组织结构==========
  CALL_NAME: '이름 분류',
  CODE: '코드',
  ORG_TOTAL_TIP: '총 {count} 개 {title}',
  DELETE_ORG_CONFIRM_TIP: '조직 구조 "{name}"를 삭제하시겠습니까?',
  DELETE_ORG_CONFIRM_SUB_TIP: '삭제 후에는 되돌릴 수 없습니다',

  ORG_NAME_EXISTED: '조직 이름이 이미 존재합니다.',
  INPUT_TEXT_VALIDATATION_TIP: '최대 {count} 문자, 영어, 숫자 및 기호 지원',
  FULL_ADDRESS: '세부 주소',

  //================= 矩阵运营 =================
  PICTURE_NOTE: '그래픽',
  PICTURE: '사진',
  LIST_WORKS: '영상 목록',
  COVER: '썸네일',
  TITLE_OF_WORK: '영상 제목',
  PUBLISH_TIME: '업로드 시간',
  ACTION_COMMENT: '댓글',
  ACTION_VIEW_INTERACTIVE: '상호작용 데이터 보기',
  TOTAL_NUMBER_OF_WORKS: '총 영상 수',
  STATIST_UNIT_TIAO: '',
  OPERATIONAL_DATA: '운영 데이터',
  INTERACTIVE_EFFECT_OF_WORKS: '영상 인터랙티브 성과',
  WORK_INDICATORS: '영상 데이터',
  DRAFT: '개요',
  PUBLISHED: '게시됨',
  REMOVED: '삭제됨',
  REPLY: '답글',
  CLOSE_REPLY: '답글 닫기',
  COMMENT_PLACEHOLDER_TIP: '',
  EMOTICON: '이모티콘',
  SEND: '발송',
  SELECT_EMOTICON: '이모지 선택',
  SUMMARY_OF_COMMENTS: '댓글 종합',
  COMMENT_TIME_ID: '댓글 시간/ ID',
  CLASSIFICATION: '분류',
  HIDE_ALL_REPLIES: '모든 답글 닫기',
  EXPAND_NUM_REPLIES: '{num} 개 댓글 열기',
  NO_MORE_DATA: '더 이상 없습니다.',
  AFTER_THE_VIDEO_IS_RELEASED: '영상 업로드 후',
  PLAY_COUNT_TODAY: '오늘의 플레이',
  SHARE_COUNT_TODAY: '오늘의 공유',
  COMMENT_COUNT_TODAY: '오늘의 댓글',
  LIKE_COUNT_TODAY: '오늘의 즐겨 찾기',
  FAV_COUNT_TODAY: '오늘의 추천 상품',
  FOLLOW_COUNT_TODAY: '오늘의 포커스',

  PLAY_TODAY: '오늘 시청 수',
  LIKE_TODAY: '오늘 좋아요',
  SHARE_TODAY: '오늘 공유',
  COMMENT_TODAY: '오늘 댓글',
  DATE: '날짜',
  LAST_NUM_DAYS: '최근 {num}일',
  LIKE_COUNT_TOTAL: '누적 좋아요 수',
  SHARE_COUNT_TOTAL: '누적 공유 수',
  COMMENT_COUNT_TOTAL: '누적 댓글 수',
  WORKS_COUNT_TOTAL: '누적 작품 수',
  MATRIX_OPERATION_STATISTICS_TIP:
    '1. 통계 데이터는 계정 최초 인증 이후 실시간으로 업데이트되며, 2. 추이 및 세부 데이터는 계정 인증 후 익일부터 표시되고, 30일 이내에 생성된 동영상에 대한 데이터만 제공됩니다.',
  WORK_DETAILS: '아트웍 세부 정보',
  COMPLETION_RATE: '완료율',
  INDICATOR_DATA: '지표 데이터',
  LIKE_NEGATIVE_NUM_TIP: '좋아요 수가 마이너스인 경우 사용자 취소로 인한 것일 수 있습니다.',
  AVG_PLAY_DURATION: '평균 플레이 시간',
  LIKE: '좋아해야 합니다.',
  RECOMMEND: '고객 후기',
  FELLOW: '초점',
  SHARE: '다른 사람들과 공유(기쁨, 혜택, 특권 등)',
  PLAY: '재생 가능',
  LIKE_COUNT_TOAL: '누적 좋아요 수',
  RECOMMEND_COUNT_TOTAL: '누적 추천 수',
  COMMENT_COUNT_NUM_TOTAL: '누적 댓글',
  FOLLOW_COUNT_TOTAL: '누적 관심도',
  SHARE_COUNT_NUM_TOTAL: '누적 공유',
  FAVORITE_DAILY_NUM: '오늘의 컬렉션',
  VIEWS_COUNT_TOTAL: '누적 조회수',
  NOTE_COUNT_TOTAL: '총 누적 노트 수',
  VIDEO_COUNT_TOTAL: '총 누적 동영상 볼륨',
  IMAGE_NOTE_COUNT_TOTAL: '총 누적 그래픽 볼륨',
  LIKED_TOTAL: '누적 좋아요 수',
  COLLECTION_TOTAL: '누적 컬렉션 수',
  BARAGE_TOTAL: '누적 팝업 횟수',
  INCREASING_NOTES_TOTAL: '누적 노트 수 증가',
  INCREASING_FUNS_TOTAL: '직접 팬 확보 누적 수',
  NOTE_TYPE: '노트 유형',
  VIEW_NUM: '시청률',
  COLLECTIONS_NUM: '컬렉션 개수',
  BULLET_COMMENTS_NUM: '깜박임 횟수(예: TV 화면)',
  INCREASING_FOLLOWERS: '팬 수 직접 늘리기',
  ITEM_CLUE_COUNT: '동영상 보존 비용',
  LIVE_CLUE_COUNT: '라이브 방송 및 자금 유지',
  OTHER_CLUE_COUNT: '기타 유보금',
  CAPTION_REQUIRED_TIP1: '작품에 대한 간략한 설명, 최대 1000개의 텍스트 입력 지원',
  CAPTION_REQUIRED_TIP2: '작품의 제목 및 시놉시스, 최대 1,000개의 텍스트 입력 지원',

  //===========作品发布======
  STATUS_UNPUBLISHED: '게시 예정',
  STATUS_PUBLISHING: '에 게시 됨.',
  STATUS_PUBLISHE_SUCCESS: '성공적인 게시',
  STATUS_PUBLISHE_FAILURE: '게시 실패',
  APPLICATION_INFO: '애플리케이션 정보',
  LINK_URL: '링크 주소',
  PLEASE_SELECT_APPLICATION: '애플리케이션을 선택해 주세요.',
  MINI_APP: '애플릿',
  SELECT_MINI_APP: '애플릿 선택',
  PLEASE_COPY_DY_APP_LINK: 'Shakeology 애플릿 링크를 복사하여 붙여넣으세요.',
  PERMISSION_SETTING: '권한 설정',
  ALLOW_SAVING_VIDEO: '동영상 저장 허용',
  WORK_INTRODUCTION: '시놉시스',
  WORK_INTRODUCTION_TYPE_INPUT_TIP: '작품에 대한 간단한 설명을 최대 {num}개의 텍스트 입력으로 추가합니다.',
  VIDEO_MOUNTING: '비디오 마운트',
  VIDEO_MOUNTING_TIP: '동영상 마운트를 설정하면 다른 계정에서 게시한 동일한 콘텐츠 마운트 정보가 동일하게 적용됩니다.',
  SEND_ACCOUNT_NUM_TIP: '{num}를 전송하도록 선택한 총 계정 수',
  POSITON_SETTING_TIP: '현재 위치를 설정하면 다른 계정에서 게시한 동일한 콘텐츠의 주소 정보가 동일하게 표시됩니다.',
  VIDEO_TITLE: '비디오 제목',
  VIDEO_TITLE_TIP: '짧은 헤드라인은 검색, 주제, 이벤트, 위치, 구독 메시지, 검색 페이지의 빨간 점 등과 같은 시나리오에 표시됩니다.',
  VIDEO_TITLE_PLACEHOLDER: '요점 요약(6~16자 권장)',
  ORIGINAL: '혁신',
  VIDEO_ORIGINAL_FIELD_TIP: '개인 번호는 독창성을 지원하지 않습니다.',
  VIDEO_ORIGINAL_FIELD_TEXT_DESCRIBE: '5초 미만의 동영상과 그래픽 콘텐츠는 원본 문구를 사용할 수 없습니다.',
  UPLOAD_VIDEO_FILE_TIP: '동영상 업로드는 mp4, mov 동영상 파일 형식의 단일 동영상만 지원합니다!',
  UPLOAD_VIDEO_DURATION: '재생 시간이 15분 이하인 동영상을 업로드해야 합니다.',
  UPLOAD_VIDEO_MAX_SIZE_TIP: '업로드한 동영상 파일의 크기는 128MB를 넘지 않아야 합니다.',
  CLICK_TO_UPLOAD: '업로드하려면 클릭',
  FILE_UPLOAD_REQUIRD_TIP:
    'MP4, WebM 형식 지원 업로드하는 동영상 파일의 크기는 128MB 이상, 동영상 길이는 15분을 넘지 않아야 합니다. 16:9 비율의 동영상 버전을 업로드하는 것이 좋으며, 해상도는 720p(1280x720) 이상이어야 합니다.',
  FILE_UPLOAD_EXCEEDED: '최대 업로드 횟수 초과',
  PUBLISH_SINGLE_IMG_REQUIRED: '단일 이미지 최대 지원 용량 20M',
  PUBISH_IMG_WIDTH_HEIGHT_REQUIRED: '그래픽의 가로 세로 비율은 1:2를 넘지 않는 것이 좋습니다.',
  PUBISH_IMG_REQUIRED:
    '일반 이미지 형식 지원, 당분간 GIF 형식은 지원하지 않음 단일 이미지의 최대 지원 용량은 20M이며, 업로드할 때마다 최대 10개의 이미지 업로드 가능 그래픽의 가로 세로 비율은 1:2를 넘지 않는 것을 권장합니다.',
  RESOUTION_TRTIO_REQUIRED_TIP: '해상도가 너무 낮습니다. 324*432 이상의 해상도로 이미지를 업로드하세요.',
  VERTICAL_SCREEN: '세로 화면',
  LANDSCAPE_SCREEN: '가로 화면',
  COVER_IMG_SCREEN_REQUIRED: 'jpeg, png, jpg 형식 지원, 최대 지원 10M 리틀 레드북 그래픽 포스팅 및 동영상 번호 그래픽 포스팅은 당분간 표지 업로드를 지원하지 않습니다.',
  TASK_NAME: '작업 이름',
  CONTENT_SETTING: '콘텐츠 설정',
  PUBLISH_TIME_REQUIRED_TIP: '게시물을 현재 시간보다 일찍 올릴 수 없습니다.',
  CONTENT_TYPE: '콘텐츠 유형',
  PUBLISH_VIDEO: '비디오 게시',
  PLEASE_UPLOAD_VIDEO: '동영상을 업로드하여 게시하세요.',
  UPLOAD_VIDEO_TIP:
    '브랜드 로고나 브랜드 워터마크가 있는 동영상은 Jitterbug의 감사 로직에 걸려 공유 동영상 추천 등급 하향 처리/공유 동영상 등급 하향 처리/공유 계정 차단 처리로 이어질 가능성이 높습니다. 타사 앱에서 공유 콘텐츠의 비준수 워터마크를 직접 처리하는 것이 좋습니다.',
  UPLOAD_PIC_TEXT: '그래픽 게시',
  PLEASE_UPLOAD_PIC_TEXT: '포스팅 그래픽을 업로드하세요.',
  PUBLISH_COVER_IMG: '릴리스 커버',
  PLATFORM_CONFIG: '플랫폼 구성',
  PUBLISH_TYPE_INSTANT_SEND: '지금 보내기',
  PUBLISH_TYPE_SCHEDULE_SEND: '시간 초과',

  PUBISH_STATUS_DRAFT_TEXT: '개요',
  PUBLISH_STATUS_PENDING_TEXT: '구현 대기 중',
  PUBLISH_STATUS_PROCESSING_TEXT: '구현 중',
  PUBLISH_STATUS_PAUSED_TEXT: '일시 중단됨',
  PUBLISH_STATUS_FINISHED_TEXT: 'done',

  PUBLISH_TASK_STATUS_PREPARATION_TEXT: '게시 예정',
  PUBLISH_TASK_STATUS_PUBLISHING_TEXT: '에 게시 됨.',
  PUBLISH_TASK_STATUS_SUCCESS_TEXT: '성공적인 게시',
  PUBLISH_TASK_STATUS_FAILURE_TEXT: '게시 실패',
  PUBLISH_TASK_STATUS_PAUSED_TEXT: '일시 중단됨',

  PERMISSION_TYPE_PUBLISH_TEXT: '공개적으로',
  PERMISSION_TYPE_PRIVATE_TEXT: '친밀한',
  PERMISSION_TYPE_FRIENDLY_TEXT: '친구에게 공개',

  ALLOW: '허용',
  NOT_ALLOW: '허용되지 않음',
  NEW_PUBLISH_ITEM: '새 작업 릴리스',
  VIEW_PUBLISH_ITEM: '아트웍 릴리스 보기',
  EDIT_PUBLISH_ITEM: '편집 작업 게시',

  PUBLISH_RECORDS: '게시 기록',
  TASK_STATUS: '임무 상태',
  SUCCESS_COUNT: '성공적인 릴리스 횟수',
  FAILURE_COUNT: '실패한 릴리스 수',
  ACTION_STOP_TASK: '위임의 정지',
  ACTION_RESTART_TASK: '미션 다시 시작',
  STOP_TASK_CONFIRM_TIP: '작업 게시를 유예할 수 있나요?',
  STOP_TASK_CONTENT_TIP: '확인 후 시스템에서 콘텐츠 게시를 일시 중단합니다.',
  RESTART_TASK_CONFIRM_TIP: '작업을 다시 시작하나요?',
  RESTART_TASK_CONTENT_TIP: '확인 후 시스템에서 작업 릴리스를 다시 시작합니다.',
  CONFIRM_DELETE_TASK_CONFIRM_TIP: '게시 작업이 삭제되나요?',
  CONFIRM_DELETE_TASK_CONTENT_TIP: '삭제 후 다시 검색할 수 없는지 확인합니다.',
  ERROR_LINK_FORMAT_TIP: '잘못된 링크 형식 또는 잘못된 매개변수',
  CONFIRM_REPUBLISH_TIP: '재발급이 되나요?',
  CONFIRM_REPUBLISH_CONTENT_TIP: '시스템에서 확인 후 즉시 콘텐츠 게시를 수행합니다.',
  ACTION_BATCH_PUBLISH: '일괄 릴리스',
  PUBLISH_STATUS: '게시 상태',
  PUBLISH_FINISH_TIME: '릴리스 완료 시간',
  PUBOISH_TITLE: '동영상 제목 게시',

  //=============直播==============
  VIDEO_INFO: '비디오 정보',
  LP_SCREEN_GUIDE_ITEM_COUNT: '실시간 포털 노출 횟수',
  LP_SCREEN_GUIDE_ITEM_WATCH_COUNT: '리드인 라이브 스트림 수',
  LP_SCREEN_GUIDE_ITEM_ENTER_RATIO: '실시간 포털 조회수',
  MALE: '남성',
  FEMALE: '여성',
  LIVE_DATA: '라이브 스트리밍 데이터',
  LIVE_ACCOUNT: '실시간 계정',
  LIVE_END_TIME: '라이브 종료 시간',
  LIVE_DURATION: '스트리밍 시간',
  ACTION_REPORT: '보고',
  ACTION_REPLAY: '다시보기',
  STATIST_UNIT_GE: '개',
  STATIST_UNIT_CI: '회',
  STATIST_UNIT_CHANG: '(라이브) 회',
  STATIST_UNIT_YUAN: '위안',
  STATIST_UNIT_REN: '',
  STATIST_UNIT_REN_CI: '명',
  STATIST_UNIT_SEC: '초',
  STATIST_UNIT_MIN: '분',
  STATIST_UNIT_HOUR: '시간',
  STATIST_UNIT_DAY: '일',
  STATIST_UNIT_WEEK: '주간',
  STATIST_UNIT_MONTH: '개월',
  STATIST_UNIT_YEAR: '성 니안',

  LIVE_STATIST_ACCOUNT_NUM: '라이브 중인 계정 수',
  LIVE_STATIST_ACCOUNT_NUM_TIP: '필터링 조건에 따라 스트리밍 중인 계정의 수를 집계합니다.',
  LIVE_STATIST_LIVE_NUM: '스트리밍 횟수',
  LIVE_STATIST_LIVE_TOTAL_NUM: '누적 생방송 횟수',
  LIVE_STATIST_LIVE_NUM_TIP: '집계된 필터링 기준에 따라 계정에서 시작한 누적 생방송 횟수를 집계합니다.',
  LIVE_STATIST_LIVE_DURATION: '누적 스트리밍 시간',
  LIVE_STATIST_LIVE_DURATION_TIP: '필터 조건에 따라 계정이 시작한 실시간 생방송의 누적 기간을 집계합니다.',
  LIVE_STATIST_VIEW_NUM: '누적 시청자 수',
  LIVE_STATIST_VIEW_NUM_TIP: '필터링 조건에 따라 계정에서 시작한 실시간 생방송의 누적 시청자 수를 집계하며, 다른 세션 간의 시청자 수는 가중치에 포함되지 않습니다.',
  LIVE_STATIST_DURATION_AVG_TIP: '필터링 조건에 따라 계정에 의해 시작된 실시간 스트리밍, 실시간 스트리밍 시청 시간(누적)/라이브 스트리밍에 참여한 사용자 수(누적)',
  LIVE_STATIST_COMMENT_NUM: '누적 댓글 수',
  LIVE_STATIST_COMMENT_NUM_TIP: '필터 조건에 따라 계정에서 시작한 실시간 생방송의 누적 댓글 수를 집계합니다.',
  LIVE_STATIST_CLUE_NUM: '누적 리드 수',
  LIVE_STATIST_CLUE_NUM_TIP: '필터링 조건에 따라 계정에서 실시간으로 시작된 누적 리드 수를 집계합니다.',
  INTERACTIVE_EFFECT: '인터랙티브 효과',
  DATA_INDICATORS: '데이터 표시기',
  LIVE_INDICATOR_WATCH_UV: '시청자 수',
  LIVE_INDICATOR_UV: '누적 시청자 수',
  LIVE_INDICATOR_UV_TIP: '전체 생방송 시청자 수',
  LIVE_INDICATOR_MAX_WATCH_UV: '최대 온라인 사용자 수',
  LIVE_INDICATOR_MAX_WATCH_UV_TIP: '전체 생방송 중 가장 높은 온라인 참석자 수',
  LIVE_INDICATOR_AVG_WATCH_DURATION_TIP: '전체 / 필드 보기에서 조회한 시간의 합계',
  LIVE_INDICATOR_FOLLOW_UV: '팔로우 증가량',
  LIVE_INDICATOR_FOLLOW_UV_TIP: '생방송 중에 얻은 팔로워 수를 보려면 왼쪽 상단 모서리에 있는 팔로워 수를 클릭합니다.',
  LIVE_INDICATOR_COMMENT_UV: '댓글 수 (명)',
  LIVE_INDICATOR_COMMENT_UV_TIP: '댓글 작업이 있는 사람 수, 강조 표시 해제된 수, 여러 개의 댓글이 있는 사람은 한 명으로 계산됩니다.',
  LIVE_INDICATOR_MSG_CONVERSATION_COUNT: 'DM 수 (명)',
  LIVE_INDICATOR_MSG_CONVERSATION_COUNT_TIP:
    '라이브 방송 중 비공개 메시지 세션을 생성한 사람의 수(사용자가 말을 하지 않았고 판매자가 선제적으로 인사말을 설정한 경우 포함)',
  LIVE_INDICATOR_CLUE_UV: '라이브 리드 수',
  LIVE_INDICATOR_CLUE_UV_TIP: '단일 라이브 쇼가 진행되는 동안 모든 채널(풍차 카드, 비공개 메시지, 스마트폰)에서 생성된 리드 수',
  LIVE_INDICATOR_WATCH_GT_MIN_COUNT: '>1분 이상 시청 수 (명)',
  LIVE_INDICATOR_WATCH_GT_MIN_COUNT_TIP: '1분 이상 프로그램을 시청한 사용자 수(인원 수 아님)',
  LIVE_INDICATOR_USER_REALTIME: '실시간 온라인 사용자 수',
  LIVE_INDICATOR_USER_REALTIME_TIP: '생방송 중 실시간 분당 사용자 수입니다. 방송이 종료되면 이 값은 0이 됩니다.',
  LIVE_INDICATOR_FANS_WATCH_UV: '팔로워수',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT: '카드 클릭 수 (명)',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT_TIP:
    '카드 (틱톡 앱의 오른쪽 하단에 있는 설명 카드와 풍차 안의 카드)의 클릭 수만 계산되며, 이는 사람 수가 아닌 클릭 수입니다.',
  LIVE_INDICATOR_SHOW_COUNT: '노출 횟수',
  LIVE_INDICATOR_SHOW_COUNT_TIP: '무거운 통계 수치 없이 라이브 스트리밍 노출을 통해 도달한 횟수',
  LIVE_INDICATOR_ENTER_RATIO: '라이브 노출율',
  LIVE_INDICATOR_ENTER_RATIO_TIP: '누적 시청자 수/노출 (명)',
  LIVE_INDICATOR_ICON_CLICK_COUNT: '작은 풍차 히트',
  LIVE_INDICATOR_ICON_CLICK_COUNT_TIP: '프레젠테이션 카드가 아닌 트롤리의 클릭 수만 계산되었고, 사람 수가 아닌 클릭 수만 계산되었습니다.',
  LIVE_INDICATOR_ICON_CLICK_RATE: '풍차 클릭률',
  LIVE_INDICATOR_ICON_CLICK_RATE_TIP: '풍차 클릭률 = 풍차 클릭 수/시청자 수',
  LIVE_INDICATOR_INTERACTION_RATIO: '상호작용 비율',
  LIVE_INDICATOR_INTERACTION_RATIO_TIP: '상호작용 횟수/누적 시청자 수',
  LIVE_INDICATOR_BIZ_WECHAT_COST: '위챗 추가 코스트',
  LIVE_INDICATOR_BIZ_WECHAT_COST_TIP: '위챗 추가 코스트 = 광고 소비량 / Wecom 신규 증가 수',
  LIVE_INDICATOR_FORM_COUNT: '양식 제출 횟수',
  LIVE_INDICATOR_FORM_COUNT_TIP: 'DOU+ 플랫폼 내 "直播大屏" 유료 트래픽은 스노우(小雪花) 배너와 외부 링크를 설정한 경우에만 사용 가능합니다.',
  LIVE_INDICATOR_WATCH_COUNT: '시청자 수',
  LIVE_INDICATOR_WATCH_COUNT_TIP: '전체 라이브 시청자 수는 중복 값이 포함되어 있습니다.',
  LIVE_INDICATOR_FANS_WATCH_RATIO: '팔로워 비중',
  LIVE_INDICATOR_FANS_WATCH_RATIO_TIP: '팔로워 시청 수/누적 시청 수',
  LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM: '평균 온라인 수 (명)',
  LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM_TIP: '분당 최고 시청자 수/라이브 방송 시간(분)',
  LIVE_INDICATOR_UV_WITH_PREVIEW: '이미 시청 함',
  LIVE_INDICATOR_UV_WITH_PREVIEW_TIP:
    '틱톡 APP 내 영상 페이지를 본 명 수는 라이브에 입장한 명 수와 라이브 미리보기 추천 페이지에 3초 이상 머물렀으나 입장하지 않은 명 수를 합한 값 입니다.',
  LIVE_INDICATOR_SHARE_COUNT: '공유 수',
  LIVE_INDICATOR_SHARE_COUNT_TIP: '누적 공유 수',
  LIVE_INDICATOR_SHARE_UV: '공유 수 (명)',
  LIVE_INDICATOR_SHARE_UV_TIP: '순 공유 수 (인당 일회)',
  LIVE_INDICATOR_SHARE_RATIO: '공유율',
  LIVE_INDICATOR_SHARE_RATIO_TIP: '공유 수(명)/누적 시청자 수',
  LIVE_INDICATOR_LIKE_UV: '좋아요 수 (명)',
  LIVE_INDICATOR_LIKE_UV_TIP: '순 좋아요 수(인당 일회)',
  LIVE_INDICATOR_LIKE_COUNT: '좋아요 수',
  LIVE_INDICATOR_LIKE_COUNT_TIP: '누적 좋아요 수',
  LIVE_INDICATOR_LIKE_RATIO: '좋아요 클릭율',
  LIVE_INDICATOR_LIKE_RATIO_TIP: '좋아요 수(명)/누적 시청자 수',
  LIVE_INDICATOR_COMMENT_COUNT: '댓글 수',
  LIVE_INDICATOR_COMMENT_COUNT_TIP: '누적 댓글 수',
  LIVE_INDICATOR_COMMENT_RATIO: '댓글 비율',
  LIVE_INDICATOR_COMMENT_RATIO_TIP: '댓글 수 (명)/누적 시청자 수',
  LIVE_INDICATOR_INTERACTION_COUNT: '상호작용 (회)',
  LIVE_INDICATOR_INTERACTION_COUNT_TIP: '좋아요, 댓글, 공유 행위 누적 집계 (명)',
  LIVE_INDICATOR_INTERACTION_UV_COUNT: '상호작용 수(명)',
  LIVE_INDICATOR_INTERACTION_UV_COUNT_TIP:
    '좋아요, 댓글, 공유 행위 수 (인당 일회)',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV: '신규 팔로워 수 (명)',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_TIP: '신규 팔로워 수 누계',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_RATIO: '팔로워율',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_RATIO_TIP: '신규 팔로워 수(명)/누적 시청자 수',
  LIVE_INDICATOR_FOLLOW_RATIO: '구독 수',
  LIVE_INDICATOR_FOLLOW_RATIO_TIP: '팔로우 증가율/ 누적 시청자 수',
  LIVE_INDICATOR_CLUE_CONVERT_RATIO: '리드 전환율',
  LIVE_INDICATOR_CLUE_CONVERT_RATIO_TIP: '전체 라이브 리드 수(명) /누적 시청자 수',
  LIVE_INDICATOR_FORM_COST: '양식 비용',
  LIVE_INDICATOR_FORM_COST_TIP: '양식 비용 = 광고 소비량/양식 제출 수',
  LIVE_INDICATOR_LONGTERM_MSG_CLUE_UV: 'DM 장기 성과 전환율',
  LIVE_INDICATOR_LONGTERM_MSG_CLUE_UV_TIP:
    '라이브 중 1:1 메시지만 남긴 고객, 라이브 종료 후 1시간 이내 리드를 남긴 고객 데이터는 -',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_RATE: '카드 클릭률',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_RATE_TIP: '카드 클릭률 = 카드 클릭 수/카드 노출 횟수',
  LIVE_INDICATOR_BIZ_WECHAT_ADD_COUNT: 'Wecom 신규 추가 수',
  LIVE_INDICATOR_BIZ_WECHAT_ADD_COUNT_TIP: '같은 이름의 거대한 광고 플랫폼 라이브 대형 화면 표시기, 작은 눈송이 또는 외부 링크를 장착할 때만 사용하도록 제한됩니다.',
  LIVE_INDICATOR_CLUE_COST: 'CPL (리드 코스트)',
  LIVE_INDICATOR_CLUE_COST_TIP: '라이브 내 광고 지출액/ 라이브 리드 수',
  LIVE_INDICATOR_STAT_COST: '광고 지출',
  LIVE_INDICATOR_STAT_COST_TIP: '해당 라이브 관련 광고 지출',
  LIVE_COMMENT_NUMBER: '총 {count} 팝업 댓글',
  LIVE_INDICATOR_TIP: '데이터 추이 분석은 최소 1차원, 최대 3차원의 데이터를 보여줍니다.',
  LIVE_STATIST_AUDIENCE_TRAFFIC_SOURCE: '잠재 고객 트래픽 소스',
  LIVE_STATIST_AGE: '{age} 세',
  LIVE_STATIST_AGE_ABOUT: '{age} n세+',
  LIVE_STATIST_AUDIENCE_DATA: '시청자 데이터',
  LIVE_STATIST_INTERACTIVE_DATA: '상호작용 데이터',
  LIVE_STATIST_CONVERT_DATA: '전환 데이터',
  SATREAMING_SHORT_VIDEO: '기분 전환용 짧은 동영상',
  TRAFFIC_CONVERSION: '트래픽 전환',
  USER_PROFILE_ANALYSIS: '사용자 프로파일링',
  RESIDENCE_DISTRIBUTION: '숙박 분포',
  TRAFFIC_ANALYSIS: '흐름 분석',
  CITY_TOP_10: 'Top10 도시',
  PROVINCE_TOP_10: 'Top10 성',
  LIVE_DETAIL_PLAY_TIP: '각 라이브에는 타사용자 팝업, 규정에 위배되지 않는 팝업만 표시됩니다.',
  LIVE_DETAIL_TAB_COMMENT: '댓글',
  LIVE_DETAIL_TAB_HIGH_LIGHT: '하이라이트',
  LIVE_INDICATOR_GROUP_POPULARITY: '인기 지표',
  LIVE_INDICATOR_GROUP_INTERACTIVE: '상호작용 지표',
  LIVE_INDICATOR_GROUP_CONVERT: '전환 지표',
  LIVE_INDICATOR_GROUP_OTHER: '기타 지표',
  REAL_TIME_DYNAMICS: '실시간 동향',
  TIME_MINUTE_TPL: '{time} 분',
  TIME_HOUR_TPL: '{time} 시간',
  LIVE_CLICK_PLAY_HIGH_LIGHT: '클릭해서 하이라이트 재생',
  LIVE_DETAIL: '라이브 세부 정보',
  SUMMARY_OF_BARRAGES: '라이브 팝업 종합',
  LIVE_VIEW_USER_COUNT: '누적 시청자 수',
  CLUE_ANALYSIS: '스레드 분석',
  CLUE_TRAFFIC_SOURCCE: '리드 흐름 소스',
  AREA_DISTRIBUTION: '지리적 분포',
  CITY: '도시',
  SEGMENTED_SCENE_ANALYSIS: '세분화 시나리오 분석',
  LIVE_ACCUMULATED_VIEWER_TOTAL: '누적 시청자 수',
  PEAK_ONLINE_USER: '최고의 온라인 존재감',
  CLUE_COLLECTION_NUM: '수집된 리드 수',
  GIFT_RECEIVED_NUM: '선물 개수',
  GIFT_COLLECTION_FLOW_NUM: '선물 부족',
  CLUE_VOMUME_MOUNTING_RATE: '리드 볼륨 장착률',
  ADD_FOLLOW: '관심사 추가',
  TOTAL_HEAT: '총 열량',
  FAN_DISTRIBUTION: '팬 배포',
  CHANNEL_DISTRIBUTION: '채널 배포',
  REGION_AREA_TOP_5: '지리적 분포 상위 5위',
  SHARE_LIVE_ROOM_USER_COUNT: '라이브룸에 있는 사람 수 공유',
  SHARE_LIVE_ROOM_TIME_COUNT: '생방송을 공유한 횟수',
  GIFT_GIVE_USER_COUNT: '선물 개수',
  GIFT_GIVE_HEAT: '선물 제공에 대한 열정',
  ENTER_ROOM_USER_COUNT: '객실 내 인원 수',
  LEAVE_ROOM_USER_COUNT: '출발 횟수',
  DATA_OVERVIEW: '데이터 개요',
  TRAFFIC_AND_SOURCE_TREND: '트래픽 및 소스 트렌드',
  TRAFFIC_TREND: '트래픽 트렌드',
  INTERACTIVE_TREND: '인터랙션 트렌드',
  CUMULATIVE_WATCH_COUNT: '누적 시청자 통계',
  ADD_TRACKING_STATISTICS: '관심사 통계 추가',
  LIVE_COVER_TITLE: '라이브 스트리밍 타이틀',

  //================工作台============
  PLAY_ANALYSIS: '재생 분석',
  CONVERT_ANALYSIS: '전환 분석',
  LIVE_PLAY_TOP10: '실시간 랭킹 톱 10',
  VIDEO_PLAY_TOP10: '실시간 랭킹 톱 10',
  FANS_SORT_TOP10: '상위 10명의 팬',
  LEADS_RORT_TOP10: '리드 볼륨 기준 상위 10위',
  PLAY_COUNT: '재생된 횟수',
  TIME: '시간',
  ASC: '오름차순',
  DESC: '내림차순',
  BY_NAME: '{name}을 누릅니다.',
  CONTENT_ANALYSIS: '콘텐츠 분석',
  LIVE_OVERVIEW: '라이브 스트리밍 개요',
  LIVE_TREND_ANAYLISIS: '라이브 스트리밍 트렌드 분석',
  LIVE_TREND: '라이브 스트리밍 트렌드',
  FANS_ANALYSIS: '팬 분석',
  RANK_STATISTICS: '랭킹 통계',
  VIDEO_OVERVIEW: '영상 개요',
  VIDEO_TREND_ANAYLISIS: '동영상 트렌드 분석',
  TRANSFORMATION_ANALYSIS: '전환 분석',
  VIDEO_CONVERSION: '영상 전환',
  LIVE_CONVERSION: '라이브 전환',
  PEOPLE_NUM: '{num}명',
  PEOPLE: '',
  NUM_CLOCK: '{num} 포인트',
  NUM_AGE: '{num} 세',
  OVER_NUM_AGE: '{num}세 이상',
  FANS_COUNT: '총 팔로워 수',
  UNKNOWN: '미지의 세계',

  LP_SCREEN_CLUE_UV: '라이브 리드 수',
  LP_SCREEN_LIVE_CARD_CLICK_UV: '리드 배너 클릭 수',
  LP_SCREEN_LIVE_SHOW_UV: '라이브 스트리밍 노출 횟수',
  LP_SCREEN_LIVE_WATCH_UV: '라이브 유입 수',

  LIVE_TOTAL: '스트리밍 횟수',
  LP_SCREEN_LIVE_COMMENT_COUNT: '댓글 수',
  LP_SCREEN_LIVE_LIKE_COUNT: '좋아요 수',
  LP_SCREEN_LIVE_SHARE_COUNT: '공유 수',
  LP_SCREEN_LIVE_VIEW_UV: '시청자 수',

  INTERACTION_TOTAL: '총 상호작용 수',
  ITEM_CLUE_TOTAL: '영상 내 리드 수',
  PLATY_TOTAL: '누적 조회수',

  COMMENT_TOTAL: '댓글 수',
  VIDEO_ITEM_TOTAL: '영상 업로드',
  LIKE_TOTAL: '좋아요 수',
  PLAY_TOTAL: '조회수',
  SHARE_TOTAL: '공유 수',
  OPERATION_ACCOUNT_TOTAL: '운영 계정 총수',
  EXCEPTION_ACCOUNT: '비정상적인 계정',
  FANS_TOTAL: '총 팔로워 수',
  CLUE_TOTAL: '총 리드 수',
  LIVT_TOTAL_TIME: '총 실시간 스트리밍 시간',
  TIMES_EVERY_WEEK: '횟수/주',
  LIVE_ACCUMULATED_WATCH_TOTAL: '누적 실시간 스트리밍 시청자 수',
  AVG_WATCH_TIME: '1인당 평균 시청 시간',
  AVG_WATCH_TIME_SECOND: '1인당 시청 시간(초)',

  LIVE_FREQUENCY: '라이브 스트리밍 빈도',
  VIDEO_PUBLISH_TOTAL: '영상 업로드 수',
  PALY_TOTAL: '누적 재생 수',
  PUBLISH_FREQUENCY: '업로드 빈도',
  LIVE_WATCH_COUNT: '시청자 수',
  LIVE_LIKE_COUNT: '좋아요 수',
  LIVE_COMMENT_COUNT: '댓글 수',
  LIVE_COMMENT_UV: '댓글 수 (명)',
  LIVE_SHARE_COUNT: '공유 수',
  THEATER_COMMAND: '(군사) 작전 극장',
  STATIST_UNIT_JIA: '가족 또는 기업(예: 상점, 회사)을 위한 분류기',
  VIDEO_TOTAL: '총 동영상 수',
  STAY_ANALYSIS: '숙박 분석',
  INTERACTIVE_ANALYSIS: '대화형 분석',
  LIKE_CLICK: 'kudos',
  RISING_FOLLOWERS_ANALYSIS: '팬 수 증가 분석',
  NEW_FUNS_COUNT: '신규 팬',
  RETENTION_ANALYSIS: '자금 유지율 분석',
  RETNETION_AMOUNT: '리텐션 머니',
  WORK_SUMMARY_ANALYSIS: '아트웍 분석',
  LIVE_SUMMARY_ANALYSIS: '라이브 스트리밍 분석',

  //=============系统授权===============
  ACCOUNT_STATUS_INIT: '초기화 진행 중',
  ACCOUNT_STATUS_EXPIRED: 'QR 코드가 만료되었습니다.',
  ACCOUNT_STATUS_CONFIRMED: 'QR 인증 완료',
  ACCOUNT_STATUS_REFUSED: '로그인 인증 실패',
  ACCOUNT_STATUS_NEW: 'QR 인증 대기중',
  ACCOUNT_STATUS_ERROR: '오류 발생',
  ACCOUNT_STATUS_SUCCESS: '인증 성공',
  ACCOUNT_STATUS_FAILURE: '인증 실패',
  ACCOUNT_STATUS_EXCEPTION: '인증 오류',
  ACCOUNT_STATUS_SCANNED: '스캔 완료',
  AUTHORIZATION_SUCCESS: '인증 성공',
  SCAN_QR_ERROR: 'QR 스캔 오류',
  AUTHORIZATION_STATUS: '인증 상태',
  LGOIN_AUTH_STATUS: '로그인 상태',
  CREATOR_AUTH_STATUS: '크리에이터 라이선스 상태',
  CREATOR_AUTH_STARTTIME: '크리에이터 라이선스 시작 날짜',
  CREATOR_AUTH_ENDTIME: '크리에이터 라이선스 종료 시간',
  ACCOUNT_TYPE: '계정 유형',
  DOWNLOAD_AUTH_LINK: '인증 링크 다운로드',
  DOWNLOAD_AUTH_LINK_TIP: '틱톡 플랫폼 인증은 195일 동안 유효하며, 로그인 인증은 60일 동안 유효함. 만료 시 재인증',
  AUTHORIZATION_PLATFORM: '인증 플랫폼',
  PLATFORM_AUTHORIZATION: '플랫폼 인증',
  DELER_CODE: '딜러 코드',
  SYNC_BI_DATA: 'BI 업데이트',
  SYNC_CLUE_RULES: '리드 규칙 업데이트',
  ACCOUNT_AUTHORIZATION: '계정 인증',
  AUTHORIZATION_EXPIRED: '인증 만료',
  ADD_ACCOUNT: '계정 추가',
  UN_AUTHORED_ACCOUNT: '인증되지 않은 계정 {num}',
  ANONMOUS_ACCOUNT_AUTHORIZATION_TIP: '현재 "{businessUnionName}" 계정 인증 진행중입니다.',
  NEW_AUTHORIZATION_ACCOUNT: '신규 계정 인증',
  ACCOUNT_AUTHORIZED_TIP:
    '승인 이후, 최근 30일 이내의 영상 데이터가 생성되며, 최근 1년 이내의 대화 관련 데이터와, 최근 60일 이내 라이브 데이터가 생성됩니다.',
  UNAUTHORIZATION_TIP: '먼저 플랫폼을 승인해 주세요.',
  UNLOGIIN_AUTHORIZATION: '먼저 인증을 위해 로그인하세요.',
  AUTHORIZATION_ORG_UNSELECTED_TIP: '소속을 선택하지 않을 시, 브랜드 틱톡 번호로 자동 설정됩니다.',
  PHONENUMBER_PLACEHOLDER: '올바른 휴대폰 번호를 입력하세요.',
  POI_POSITION: '위치',
  REMARK: 'Remark',
  OPERATOR_PHONE_NUM: '운영자의 휴대폰 번호',
  OPEN_QR_CODE_POP_UP_TIP: '클릭하여 QR코드 확인',
  AUTHORIZATION: '인증',
  REAUTHORIZATION: '재인증',
  LOGIN_AUTHORIZATION: '로그인 인증',
  CREATOR_AUTHORIZATION: '크리에이터 라이선스',
  PLEASE_AGREE_AUTHORIZE: '먼저 인증에 동의해 주세요.',
  PLEASE_PLATFORM_AUTHORIZE: '먼저 플랫폼을 승인해 주세요.',
  CONFIRM_REAUTHORIZATION: '재인증 하시겠습니까?',
  AFTER_REAUTHORIZATION_TIP: '재인증 시 이전 인증 정보는 초기화 됩니다.',
  AUTHORIZATION_TIME: '승인 시간',
  CHECK_INDICATE_AGREEMENT: '동의할 시 선택',
  APPLICAITON_LICENSE_AGREEMENT: '애플리케이션 라이선스 동의 계약',
  ACCOUNT_AUTHORIZATION_CANCELED_TIP: '해당 계정의 인증이 틱톡 백그라운드 관리자에 의해 취소되었습니다. 클릭하여 재인증 하세요.',
  ACCOUNT_AUTHIRIZATION_EXPIRED_TIP: '해당 계정의 인증이 만료되었습니다. 클릭하여 재인증 하세요.',
  CLICK_HERE: '클릭하여',
  GOTO_PLATFORM_AUTHORIZATION_PAGE_TIP: '클릭하여 틱톡 인증 페이지로 이동',
  OBTAINED_FOLLOWING_PERMISSION: '다음 권한이 허용됩니다.',
  WILL_OBTAINE_FOLLOWING_PERMISSION: '다음과 같은 권한이 부여됩니다.',
  QUICK_ANS_SECURE_LOGIN: '빠르고 안전한 로그인',
  RETRACT_UNOBTAINED_FOLLOWING_PERMISSION: '무단 액세스 차단',
  EXPEND_UNOBTAINED_FOLLOWING_PERMISSION: '사용할 수 없는 권한',
  REQUIED_ITEM_CHECKED_TIP: '필수 입력란과 관련 항목이 올바르게 입력되었는지 확인해 주세요!',
  PHONE_NUMBER: '휴대폰 번호',
  DELER_CODE_REQUIRED_TIP: '딜러 코드를 입력하세요.',
  ENTERPRISE_ACCOUNT: '기업 아이디',
  PERSONAL_ACCOUNT: '개인 아이디',
  CLOSE: '닫기',
  TRY_REFRESH_QRCODE_TIP: 'QR 코드 새로 고침 중 오류가 발생했습니다. 다시 시도하세요.',
  CLICK_TO_REAUTHORIZE: '인증을 다시 시작하려면 클릭하세요.',
  CLICK_LOAD_QRCODE: 'QR 코드를 로드하려면 클릭하세요.',
  CLICK_TO_RELOAD: '로드하려면 클릭',
  COPY_AUTHORIZATION_INFO: '인증 정보 복사',
  COPY_TEXT_TO_CLIPBOARD: '텍스트가 클립보드에 복사되었습니다!',
  COPY_TEXT_FAILURE: '텍스트 복사에 실패했습니다.',
  AUTH_LINK: '인증 링크',
  LOAD_MROE: '더 보기',
  SELECT_POSITION: '위치를 선택하세요.',
  CLEAR: '지우기',
  SELECT_AFTER_INPUT_KEYWORD: '키워드 선택 후 입력하세요.',
  PLEASE_SET_POSIITON: '위치를 설정하세요.',
  POSITION: '위치',
  REGION: '지역',
  PLATFROM_QR_AUTH_TIP1: '휴대폰 번호 받기',
  PLATFROM_QR_AUTH_TIP2: '{platform} 아바타, 닉네임 받기',
  PLATFROM_QR_AUTH_TIP3: '{platform} 계정에 공개 저작물을 게시합니다(권한이 없어도 게시할 수 있습니다).',
  PLATFROM_QR_AUTH_TIP4: '게시한 {platform} 동영상에 대한 데이터(재생 수, 좋아요 수, 댓글 수, 공유 수)를 확인하세요.',
  AUTHORIZATION_INFO: '인증 정보',
  ORG_CODE: '조직 코드',
  VIEW_AUTHORIZED_ACCOUNT: '승인된 계정 보기',
  LOGIN_AUTH_START_TIME: '로그인 인증 시작 시간',
  LOGIN_AUTH_END_TIME: '로그인 인증 종료 시간',
  PLATFORM_AUTH_END_TIME: '플랫폼 인증 시작 시간',
  OPEN_APP_AND_SCAN_QR: '{platform}앱을 사용하고 [홈 - 더보기 - 스와이프]를 엽니다.',

  //============系统语义分类========
  KEYWORD_CHANGED_TIP: '의미 카테고리 수정 혹은 추가 이후 익일 자정부터 적용됩니다.',
  NO_KEYWORD_CLASSFICATION: '키워드 분류 없음',
  KEYWORD_TOTAL_TIP: '총 {count} 개 키워드 카테고리',
  CONVERSATION: '대화',
  COMMENT: '댓글',
  BARRAGE: '팝업',
  CLASSIFICATION_NAME: '이름 분류',
  SEMANTIC_CLASSIFICATION_TYPE: '포함된 의미 카테고리',
  KEYWORD: '키워드',
  INCLUDE_KEYWORDS: '관련 키워드',
  EXCLUDED_KEYWORDS: '차단된 키워드',
  CONFIRM_DELETE_KEYWORD_TIP: '카테고리 {name} 및 키워드를 삭제하시겠습니까?',
  CONFIRM_DELETE_KEYWORD_SUB_TIP: '삭제 후 내용에 차단 키워드가 포함되어 있어도 의미 분류나 통계는 되지 않습니다.',
  CONFIRM_ENABLE_KEYWORD_TIP: '카테고리 {name} 및 키워드를 삭제하시겠습니까?',
  CONFIRM_ENABLE_KEYWORD_SUB_TIP: '활성화 이후 의미 분류가 익일 자정부터 적용됩니다',
  CONFIRM_DISABLE_KEYWORD_TIP: '카테고리 {name} 및 키워드 비활성화를 확인하시겠습니까?',
  CONFIRM_DISABLE_KEYWORD_SUB_TIP: '비활성화하면 차단된 키워드가 포함되어 있어도 콘텐츠가 의미론적으로 분류되지 않으며 귀납적 통계가 생성되지 않습니다.',
  CONFIG_KEYWORD_RULE_TIP: '관련 키워드에 하나 이상의 키워드 또는 동적 규칙을 설정합니다.',
  CONFIG_KEYWORD_RULE_REPEATION_TIP: '관련 키워드 또는 차단된 키워드 사이에 중복 키워드 "{keyword}"가 존재합니다.',
  PLEASE_ENTER_CATEGROY_NAME: '카테고리 이름을 입력하세요.',
  CATEGROY_NAME_VERIFICATION_TIP: '분류 이름에는 중국어와 영어, 숫자만 포함할 수 있습니다.',
  CATEGROY_EXISTED: '카테고리가 이미 존재합니다.',
  AFFECT: '효과',
  RULE_CONFIG: '규칙 구성',
  INCLUDE_KEYWORDS_CONFIG_SUB_TIP: '내용 중 관련 키워드가 포함되면 의미 분류가 적용됩니다.',
  EXCLUDE_KEYWORDS_CONFIG_SUB_TIP: '내용 중 차단 키워드가 포함되면 의미 분류가 적용되지 않습니다.',
  KEYWROD_VERIFICATION_RULE_TIP:
    'Enter키를 눌러 행을 변환할 수 있으며, 최대 100개 키워드를 입력할 수 있습니다. 각 키워드는 최대 50자까지 입력할 수 있으며, 중국어, 영어, 숫자만 입력 가능합니다. (대소문자 모두 가능)',

  KEYWROD_VERIFICATION_RULES_LENGH_TIP: '최대 100개의 규칙 추가',
  SINGLE_KEYWROD_RULE_TEXT_LENGH_TIP: '단일 규칙의 길이는 최대 50자까지 가능합니다.',
  KEYWROD_RULE_TEXT_FORMATTER_TIP: '중국어, 영어, 숫자만 지원하며 대소문자는 구분하지 않습니다.',

  KEYWROD_VERIFICATION_RULE_PLACEHOLDER: '한 줄당 중국어, 영어, 숫자 및 특수기호 중 하나를 포함합니다',
  DYNAMIC_RULE: '동적 규칙',
  CONFIG_RULE_REQUIRED_TIP: '하나 이상의 규칙을 구성해야 함',
  CONFIG_RULE_ITEM_REQUIRED: '규칙의 필수 필드',
  SYSTEM_RULE: '시스템 규칙',
  CUSTOM_RULE: '사용자 지정 규칙',
  CUSTOM_RULE_NAME: '사용자 지정 규칙 이름',
  PLEASE_SELECTED_RULE: '규칙을 선택해 주세요.',
  PLEASE_ENTER_RULE_NAME: '사용자 지정 규칙 이름을 입력하세요.',
  PLEASE_ENTER_REGULAR_EXPRESSION: '올바른 표현을 입력하세요',
  REGULAR_EXPRESSION_VERIFICATION: '올바른 표현에 대한 유효성 검사',
  REGULAR_EXPRESSION: '올바른 표현',
  PLEASE_ENTER_RIGHT_REGULAR_EXPRESSION: '정상적인 올바른 표현을 입력하세요',
  CLASSIFICATION_AND_DATA_FILLED_TIP: '카테고리 이름과 샘플 데이터를 모두 입력해야 합니다.',
  SAMPLE_DATA: '샘플 데이터',
  PLEASE_ENTER_SAMPEL_DATA: '샘플 데이터를 입력하세요.',
  VERIFICATION_RESULT: '확인 결과',
  CLICK_TO_VIEIFY: '확인하려면 클릭',
  CLICK_TO_REVALIDATION: '다시 확인하려면 클릭',
  PASS: '통과(청구서 또는 검사 등)',
  NOT_PASS: '실패',
  RULE_TYPE: '규칙 유형',
  RULE_NAME: '규칙 이름',
  CREATE_KEYWORD: '의미 카테고리 생성',
  VEIW_KEYWORD: '의미 카테고리 보기',
  EDIT_KEYWORD: '의미 카테고리 편집',

  //==============权限管理==============
  RESOURCE: '자원(예: 인력 또는 관광)',
  ATTRIBUTE_SCOPE: '속성 범위',
  OPERATE_TYPE: '작업 유형',
  ORG_STRUCTURE: '조직 구조',
  ACTION_ADD_USER: '사용자 추가',
  ACTION_BATCH_REMOVE: ' 일괄 제거',
  RANDOM: '확률론적',
  SELECT_NAME: '{name} 선택',
  ROLE: '캐릭터',
  USER_GROUP: '사용자 그룹',
  ROLE_NAME: '캐릭터 이름',
  FUNCTION_PERMISSION: '기능적 권한',
  SUPPORT_CN_EN_NUMBER_UNLINE_TEXT_TIP: '중국어, 영어, 숫자 및 밑줄을 지원합니다.',
  SUPPORT_EN_NUMBER_UNLINE_TEXT_TIP: '중국어, 영어, 숫자 및 밑줄을 지원합니다.',
  COUNT_CN_EN_NUMBER_UNLINE_TEXT_REQUIRD_TIP: '최대 {count}자, 중국어, 영어, 숫자 및 밑줄을 지원합니다.',
  COUNT_CN_EN_NUMBER_UNLINE_SYMBOL_TEXT_REQUIRD_TIP: '최대 {count}자, 중국어, 영어, 숫자 및 기호를 지원합니다.',
  NAME_EXISTED: '{name}이 이미 존재합니다.',
  FUNCTION_PERMISSION_SET_TIP: '사용자 그룹에서 작업 권한에 해당하는 데이터 범위를 설정하세요.',
  PERMISSION_CONFIG: '권한 구성',
  USER_COUNT: '사용자 수',
  USERGROUP_NAME: '사용자 그룹 이름',
  BIND_USERGOUP: '연결된 사용자 그룹',
  OPERATION_PERMISSION: '운영 권한',
  CONFIRM_DELETE_USER_CONTENT_TIP: '삭제된 사용자는 검색되지 않으며 시스템에 로그인할 수 없습니다.',
  CONFIRM_DELETE_ROLE_CONTENT_TIP: '삭제된 역할은 복구할 수 없으며 사용자 권한이 제거됩니다.',
  CONFIRM_DELETE_USERGROUP_CONTENT_TIP: '사용자 그룹을 삭제하면 연결된 사용자의 권한이 제거됩니다.',

  USERNAME: '사용자 ID',
  EMAIL: '받은 편지함',
  BIND_ROLE: '관련 역할',
  DATA_PERMISSION: '데이터 권한',
  CURRENT_GROUP: '사용자 그룹',
  OVERVIEW_PERMISSION: '데이터 권한 목록',
  CONFIRM_DISABLE_USER: '사용자가 비활성화되어 있는지 확인하시겠습니까?',
  CONFIRM_DISABLE_USER_CONTENT_TIP: '비활성화된 사용자는 시스템에 로그인할 수 없습니다.',
  GROUP_USERS: '그룹 내 사용자',
  USER_CONFIG: '사용자 구성',
  ACTION_SAVE_AND_CONFIG_PERMISSION: ' 권한 저장 및 구성',
  CONFIG_PERMISSION: '권한 구성',
  CONFIG_USERGROUPS: '사용자 그룹 구성',
  PLEASE_CONFIG_NAME: '{name}을 구성해 주세요.',
  CONFIG_PERMISSION_VALIDATE_TIP: '데이터 권한의 경우 필수 필드가 채워지지 않습니다.',
  OPERATOR_SYMBOL: '중매인',
  EQUAL: '와 같은 의미입니다.',
  NOT_EQUAL: '같지 않음 ≠',
  MODULE: '모듈(소프트웨어 내)',
  RESOURCE_OBJ: '리소스 객체',
  ROLE_CONFIG_ATTR_MISSING: '현재 데이터 권한 역할 개체가 삭제되었으니 다시 구성하세요.',
  NO_PERMISSION_VISIT: '액세스 권한 없음',
  PLEASE_CONTACT_ADMINISTRATOR_TO_OBTAIN_PERMISSION: '허가를 받으려면 관리자에게 문의하세요.',
};
