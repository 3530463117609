export default {
  APP_NAME: '社媒矩阵管理',
  LOGIN: '登录',
  LOGIN_NAME: '登录名',
  PASSWORD: '密码',
  USERNAME_INPUT_TIP: '请输入用户名',
  PASSWORD_INPUT_TIP: '请输入密码',
  VERIFICATION_CODE_INPUT_TIP: '请输入验证码',
  ERROR_USER_PWD_TIP: '用户名或密码不正确！',

  EXIT: '退出',
  MULTILINGUAL: '语言',
  CHANGE_PASSWORD: '修改密码',
  CONFIRM_PASSWORD: '确认密码',
  NEW_PASSWORD: '新密码',
  OLD_PASSWORD: '原密码',
  PWD_RULE_TIP: '密码至少8位字符，且必须包含数字、英文字母或特殊字符，至少匹配两种规则',
  CONFRIM_PWD_NOT_SAME_TIP: '密码输入不一致！',
  PAGINATION_INFO_TIP: '共 {total} 条记录，当前为 {start} - {end} 条',
  ACTION_SUBMIT: '提交',
  ACTION_DELETE: '删除',
  ACTION_REMOVE: '移除',
  ACTION_SAVE: '保存',
  ACTION_SAVA_DRAFT: '存为草稿',
  ACTION_SUBMIT_PUBLISH: '提交发布',
  ACTION_DOWNLOAD: '下载',
  ACTION_DISABLE: '禁用',
  ACTION_ENABLE: '启用',
  ACTION_DOWNLOAD_DATA: '下载数据',
  ACTION_VIEW: '查看',
  ACTION_EDIT: '编辑',
  ACTION_OPERATE: '操作',
  ACTION_SEARCH: '搜索',
  ACTION_NEW: '新建',
  ACTION_CANCEL: '取消',
  ACTION_ADD: '添加',
  LOGIN_PWD: '登录密码',
  COPY: '复制',

  STATUS: '状态',

  OPERATION_SUCCESS: '操作成功',
  DELETE_SUCCESS: '删除成功',
  CREATE_SUCCESS: '创建成功',
  CHANGE_PASSWORD_SUCCESS: '密码修改成功',
  EDIT_SUCCESS: '编辑成功',
  OPERATION_FAILURE: '操作失败',
  DELETE_FAILURE: '删除失败',
  VALIDATION_EXCEIPTION: '验证异常',
  NO_PREMISSION: '您暂无权限操作此页面！',
  NO_DATA: '暂无数据',
  CREATE_TIME: '创建时间',
  CREATOR: '创建者',

  CONFIRM_DELETE_NAME_TIP: '确认是否删除{name}?',

  //============组件========
  NUM_MONTH: '{num}个月',
  NUM_WEEK: '{num}周',
  NUM_DAY: '{num}天',
  NUM_HOUR: '{num}小时',
  NUM_MINUTE: '{num}分',
  NUM_SECOND: '{num}秒',
  JUST_NOW: '刚刚',
  DATE_BEFORE: '前',
  DATE_AFTER: '后',
  ORGANIZATIONAL_ACCOUNT: '组织账号',
  PLAY_VIDEO: '播放视频',
  CLICK_TO_PC_WATCH_VIDEO: '点击至PC版查看视频',
  CLICK_TO_WATCH: '点击观看',
  ACCOUNT: '账号',
  APPLY: '应用',
  SELECTED_NUM_COLUMNS: '已选{num}列',
  RESET_TO_DEFAULT: '恢复默认',
  CLEAR_EMPTY: '清空',
  ABOVE_LIST_CANNOT_BE_MODIFIED: '以上列不可修改',
  ERROR_LOADING_COMPONENT_REFRESH_PAGE: '加载组件时出错，请刷新页面重试',
  PLEASE_SELECT_AFFILIATED_ORGANIZATION: '请选择所属组织',
  CUMULATIVE: '累计',
  AUTHORIZATION_SUCCESSFUL: '授权成功',
  YESTERDAY: '昨天',
  PLAY_HISTORY_LIVE_VIDEO: '播放历史直播视频',
  AUTO_LOCATE_CITY: '自动定位城市',
  MANUALLY_FILL_IN_REGION: '手动填写地域',
  SOURCE_PAGE: '来源页',
  COMPONENT_TYPE: '组件类型',
  SERVICE_CLUE_SOURCE: '服务号线索来源',
  CALL_HISTORY: '通话记录',
  LEAD_PROVINCE_ORIGIN: '线索用户归属地省份',
  LEAD_CITY_ORIGIN: '线索用户归属地城市',
  ADVERTISING_GROUP_ID: '广告组ID',
  LANDING_PAGE_ID: '落地页ID',
  ADVERTINSING_CREATIVAITY_ID: '广告创意ID',
  ADVERTINSING_CREATIVAITY: '广告创意',
  ADVERTINSING_GROUP_NAME: '广告组名称',
  LANDINGPAGE_NAME: '落地页名称',
  PURPOSE_INFO: '意向信息',
  INTENDED_GOODS: '意向商品',
  INREND_PROVINCE_NAME: '线索用户意向省份',
  INREND_CITY_NAME: '线索用户意向城市',
  STORE_PROVINCE: '门店信息-省份',
  STORE_CITY: '门店信息-城市',
  STORE_NUMBER: '门店信息-门店编码',
  STORE_INFO_NAME: '门店信息-门店名称',
  FORM_ID: '表单ID',
  FORM_NAME: '表单名称',
  PAY_ORDER_NO: '支付表单订单编号',
  PAY_ORDER_STATE_DES: '支付表单订单状态描述',
  COUPON_ID: '卡券组件-卡券ID',
  COUPON_NAME: '卡券组件-卡券名称',
  COUPON_NUMBER: '卡券组件-券码',
  COUPPON_PHONE: '卡券组件-手机号',
  COUPON_EXPIRE: '卡券组件-到期时间',

  NUMBER_OF_LEADS: '留资次数',
  ADVERTISEMENT_INFORMATION: '广告信息',
  ADVERTISER_ID: '广告主ID',
  ADVERTISER_NAME: '广告主名称',
  AD_CAMPAIGN_ID: '广告计划ID',
  AD_CAMPAIGN_NAME: '广告计划名称',
  CREATIVE_ID: '创意ID',
  ADVERTISEMENT_ID: '广告ID',
  ADVERTISEMENT_NAME: '广告名称',
  COMPANY_INFORMATION: '企业信息',
  OTHER_INFORMATION: '其他信息',
  LEAD_ID: '线索ID',
  CAR_INFORMATION: '汽车信息',
  INTENDED_CAR_SERIES: '意向车系',
  INTENDED_CAR_MODEL: '意向车型',
  PURCHASE_INTENTION: '购车意向',
  OTHER: '其他',
  OPERATOR_MOBILE_NUMBER: '运营者手机号',
  REGION_AREA: '大区',
  REGION_NAME: '大区名称',
  DEALER: '经销商',
  DEALER_NAME: '经销商名称',
  EMPLOYEE: '员工',
  EMPLOYEE_NAME: '员工名称',
  STORE: '门店',
  STORE_INFO: '门店信息',
  STORE_NAME: '门店名称',
  STORE_CODE: '门店编码',
  INFLUENCER: '达人',
  INFLUENCER_NAME: '达人名称',
  DEALER_CODE: '经销商代码',
  TIKTOK_ACCOUNT_INFORMATION: '抖音账号信息',
  ACCOUNT_INFORMATION: '账号信息',
  TIKTOK_NICKNAME: '抖音昵称',
  PLATFORM_TYPE: '平台类型',
  TOTAL_VIDEOS: '视频总数',
  NEW_FANS: '新增粉丝',
  NET_FOLLOW_FANS: '净增粉丝',
  NEW_LEADS: '新增线索',
  HOMEPAGE_VISITORS: '主页访问人数',
  SHORT_VIDEO: '短视频',
  NEW_VIDEOS: '新增视频',
  NEW_PLAY: '新增播放',
  NEW_LIKES: '新增点赞',
  NEW_COMMENTS: '新增评论',
  NEW_SHARES: '新增分享',
  DURATION_MINUTES: '时长（分钟）',
  AUDIENCE_NUMBER: '观众数',
  AVERAGE_STAY_DURATION_MINUTES: '平均停留时长(分钟)',
  AVERAGE_STAY_DURATION: '平均停留时长',
  LEAD_GENERATION: '留资',
  LIVE_SCENE_LEAD_NUMBER: '直播全场景线索人数',
  LIVE_ROOM_FORM_SUBMITTED_LEAD_COUNT: '直播间表单提交线索次数',
  ORANGE_SITE_LEAD_COUNT: '橙子建站线索次数',
  ORGANIC_TRAFFIC_LEAD_COUNT: '自然流量线索次数',
  TOTAL_LEADS_COUNT: '留资总数（次）',
  VIDEO_LEADS: '视频留资（次）',
  LIVE_LEADS: '直播留资（次）',
  MESSAGE_LEADS: '私信留资（次）',
  HOMEPAGE_LEADS: '主页留资（次）',
  ENTER_MESSAGE_SESSION_NUMBER: '进入私信会话人数',
  MESSAGE_SESSION_INITIATOR_NUMBER: '私信会话开口人数',
  MESSAGE_LEAD_QUANTITY: '私信线索量（人数）',
  MESSAGE_LEAD_CONVERSION_RATE: '私信线索转化率',
  ACCEPTED_MESSAGES: '接受消息数',
  MISSED_MESSAGES: '漏回消息数',
  THREE_MIN_REPLY_RATE: '3min回复率',
  ADVERTISEMENT: '广告',
  AD_DIRECT_LEAD_QUANTITY: '广告-直接线索量',
  AD_INDIRECT_LEAD_QUANTITY: '广告-间接线索量',
  AD_SPEND: '广告消耗',
  AD_LEAD_COST: '广告线索成本',
  DIRECT_LEAD_COST: '直接线索成本',
  AD_IMPRESSIONS: '广告展示次数',
  AD_CLICK_THROUGH_RATE: '广告点击率',
  NATIVE_AD_MANAGEMENT_NEW_FANS: '原生广告经营-新增粉丝量',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_SHARES: '原生广告经营-短视频分享量',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_COMMENTS: '原生广告经营-短视频评论量',
  NATIVE_AD_MANAGEMENT_SHORT_VIDEO_LIKES: '原生广告经营-短视频点赞量',
  NATIVE_AD_MANAGEMENT_HOMEPAGE_VISITS: '原生广告经营-主页访问人数',
  NATIVE_AD_MANAGEMENT_ENTER_MESSAGE_PEOPLE: '原生广告经营-进入私信人次',
  LP_SCREEN_LIVE_WATCH_UV_TOTAL: '直播观众数',
  LIVE_STREAM_THEME: '直播主题',
  EXPOSURE_UV: '气泡曝光人数',
  EXPOSURE_CLICK_UV: '气泡点击人数',
  COLLECTED_LEAD_NUM: '已收集线索数',
  EXPOSURE_CONVISION_RATE: '曝光点击转化率',
  LIVE_DIRATION: '直播时长(分钟)',
  XHS_ACCOUNT_NICKNAME: '小红书昵称',
  NEW_IMAGE_TEXT_FANS_COUNT: '图文新增粉丝',
  INCREASED_FANS_NUM_FROM_VIDEO: '视频新增粉丝',
  INCREASED_FANS_NUM_FROM_NOTE: '笔记新增粉丝',
  IMAGE_TEXT_TOTAL: '图文总量',
  NOTE_TOTAL: '笔记总量',
  XHS_ACCOUNT: '小红书号',
  ADD_PICTURE_TEXT: '新增图文',

  ADD_PLAY_FROM_PICTURE_TEXT: '图文新增观看',
  ADD_COMMENT_FROM_PICTURE_TEXT: '图文新增评论',
  ADD_LIKE_FROM_PICTURE_TEXT: '图文新增点赞',
  ADD_SHARE_FROM_PICTURE_TEXT: '图文新增分享',
  ADD_FAV_FROM_PICTURE_TEXT: '图文新增收藏',
  ADD_RECOMMENDS: '新增推荐',

  TURN_ON_NAVIGATION: '开启导航',
  TURN_OFF_NAVIGATION: '关闭导航',
  HOME_PAGE: '首页',
  COPY_SUCCESSFUL: '复制成功',

  //=============平台=========
  PLATFORM: '平台',
  PLATFORM_DOUYIN: '抖音',
  PLATFORM_KUAISHOU: '快手',
  PLATFORM_XIHONGSHU: '小红书',
  PLATFORM_SHIPINGHAO: '视频号',

  //=============菜单==============
  WORKBENCH: '工作台',
  USER_CENTER: '用户中心',
  DATA_VIEW: '数据概览',
  DATA_STATISIICS: '数据统计',
  PLATFORM_ACCOUNT: '平台账号',
  LIVE_MANAGE: '直播管理',
  LIVE_LIST: '直播列表',
  VIDEO_OPERATIONS: '矩阵运营',
  WORK_MANAGEMENT: '作品管理',
  WORK_PUBLISH: '作品发布',
  INTERACTIVE_MANAGEMENT: '互动管理',
  CONVERSATION_MANAGEMENT: '会话管理',
  SESSION_AGGREGAITION: '会话聚合',
  HISTORICL_CONVERSATION: '历史会话',
  COMMENT_MANAGEMENT: '评论管理',
  LIVE_BARRAGE_MANAGEMENT: '直播弹幕管理',
  CUSTOMER_ACQUISITION_MANAGEMENT: '获客管理',
  CLUE_MANAGEMENT: '线索管理',
  HIEGH_POTENTIAL_USERS: '高潜用户',
  SYSTEM_CONFIGATION: '系统配置',
  AUTHORIZED_ACCOUNT: '授权账号',
  SEMANTIC_CLASSIFICATION: '语义分类',
  ORGANIZATIONAL_MANAGEMENT: '组织管理',
  PERMISSION_MANAGE: '权限管理',
  PERMISSION_USER: '用户管理',
  PERMISSION_USERGROUP: '用户组管理',
  PERMISSION_ROLE: '角色管理',

  //=============表单提示==============
  PLEASE_SELECT: '请选择',
  PLEASE_SELECT_MULTIPLE_NAME: '-请选择{name}（多选）-',
  PLEASE_SELECT_SINGLE_NAME: '-请选择{name}-',
  PLEASE_TYPE: '请输入',
  PLEASE_TYPE_NAME: '请输入{name}',
  PLEASE_TYPE_NUMBER: '请输入数字',
  BASIC_INFO: '基本信息',
  CREATE_NAME: '创建{name}',
  EDIT_NAME: '编辑{name}',
  VIEW_NAME: '查看{name}',

  //=============账户==============
  FANS: '粉丝',
  VIDEO: '视频',
  LIVE: '直播',
  LIVT_BROADCAST_TOTAL: '直播总场次',
  TOTAL_NEW_FANS: '新增粉丝数',
  TOTAL_LOST_FANS: '流失粉丝数',
  OVERVIEW: '总览',
  TREND_ANALYSIS: '趋势分析',
  FAN_PORTRAIT: '粉丝画像',
  USER_ANALYSIS: '用户分析',
  GENDER_DISTRIBUTION: '性别分布',
  AGE_DISTRIBUTION: '年龄分布',
  VIEW_REPORT: '查看报告',
  COMMENT_TIME: '评论时间',
  NICKNAME: '用户昵称',
  AFFILIATION_ORG: '所属组织',
  ACCOUNT_NAME: '账号名称',
  AUTHORIZE_ACCOUNT_NAME: '授权账号名称',
  ACCOUNT_REPORT_AUDIENCE_ACTIVE_TIME: '受众活跃时间',
  ACCOUNT_REPORT_PROVINCE_DISTRIBUTION: '常驻省份排行Top10',
  ACCOUNT_REPORT_CROWD_DISTRIBUTION: '八大人群分布情况',
  REGION_REPORT_DISTRIBUTION_TOP: '地区分布Top10',
  INTEREST_REPORT_DISTRIBUTION_TOP: '兴趣分布Top10',
  INTEREST_DISTRIBUTION: '兴趣分布',
  ACCESS_DEVICES: '访问设备',
  ACTIVE_TIME: '活跃时间',
  SYSTEM_DISTRIBUTION: '系统分布',
  DATA_TRANDS: '数据趋势',
  FUNS_SOURCE: '粉丝来源',
  PROVINCE_DISTRIBUTION: '省级分布',
  CITY_DISTRIBUTION: '城市分布',
  LIVE_ROOM_ID: '直播间ID',
  LIVE_ROOM_ID_TIP: '请输入数字,多个用英文逗号分隔',
  LIVE_TIME: '开播时间',
  RANKINGS: '排名',
  PROVINCE: '省',
  PEOPLE_COUNT: '人数',
  TIME_COUNT: '次数',
  PERCENTAGE: '占比',
  NOTE: '笔记',

  //=============交互管理==============
  INTERACTIVE_COMMENT_DATA_TIPS: '每个直播间只展示非自见或者不涉及官方规则的弹幕',
  BULLET_CONTENT: '弹幕内容',
  KEYWORD_CLASSIFICATION: '关键字分类',
  KEYWORD_HIT: '命中关键词',
  PUBLISH_ACCOUNT: '发布账号',
  ORGANIZATION_TYPE: '组织类型',
  ACTION_LIVE_REPLAY: '直播回放',
  ACTION_VIEW_COMMENT: '查看评论',
  ACTION_MORE_BARRAGE: '更多弹幕',
  NO_COMMENT: '暂无评论',
  COMMENT_COUNT_TIP: '共 {count} 个评论',
  DATA_UPDATE_TIME_TIP: '当前数据更新时间为: {syncTime}',
  AFFILIATION_PLATFORM: '所属平台',
  SEND_TIME: '发送时间',
  ALL: '全部',
  UNCLASSIFIED: '未分类',
  COMMENT_CONTENT: '评论内容',
  WORK: '作品',
  WORK_ACCOUNT: '作品账号',
  CONVERSATION_CARD_TIP: '[卡片消息] 暂不支持该消息类型 请到app端查看',
  CONVERSATION_NOT_SUPPORT_TIP: '暂不支持该消息类型 请到app端查看',
  CONVERSATION_INFO: '会话信息',
  CONVERSATION_CURRENT: '本次会话',
  CONVERSATION_ALL: '全部会话',
  CONVERSATION_SEARCH_TIP: '搜索会话内容',
  TIKTOK_ACCOUNT: '抖音号',
  MATRIX_ACCOUNT_NAME: '矩阵账号名',
  ADDRESS: '地址',
  USER: '用户',
  SESSION_RECORDS: '会话记录',
  MESSAGE_COUNT: '消息总数',
  SESSION_TIME: '对话时间',
  SESSION_END_TIME: '会话结束时间',
  SESSION_DURATION: '对话时长',
  SESSION_ACCOUNT: '对话账号',
  NO_CONVERSATIONS: '暂无会话',
  TOTAL_CONVERSATIONS_COUNT: '共 {count} 个会话',
  TOTAL_COUNT: '共 {total} 条',

  //=============获客管理==============
  SOURCE_TIKTOK_ACCOUNT: '来源抖音号',
  SOURCE_TIKTOK_NICKNAME: '来源抖音昵称',
  SHIPINGHAO_NICKNAME: '视频号昵称',
  SHIPINGHAO_ACCOUNT: '视频号',
  CLUE: '线索',
  CLUE_TYPE: '线索类型',
  CLULE_ACCOUNT: '线索账号',
  INTERACTION_TYPE: '互动类型',
  CLUE_CHANNEL: '线索渠道',
  FLOW_TYPE: '流量类型',
  NAME: '姓名',
  CLUE_CRATE_TIME: '线索创建时间',
  LAST_ACTION_TIME: '最近互动时间',
  CUSTOM_COLUMNS: '自定义列',
  LIVE_BROADCAST_ROOM_BARRAGE: '直播间弹幕',
  VIDEO_COMMENT: '视频评论',
  VIEW_DETAIL: '查看详情',
  RERA: '所属地域',
  CLUE_DETAIL_COMMENT_TPL: '{nickName} 通过 {accountName} 在{mediaName}-《{title}》留下评论:“{content}”',
  CLUE_DETAIL_BULLET_TPL: '{nickName} 通过 {accountName} 在{mediaName} 直播中发送弹幕:“{content}”',
  CLUE_DETAIL_SESSION_TPL: '{nickName} 通过 {accountName} 发起{mediaName}私信对话: “{content}”',
  CLUE_DETAIL_CLUE_TPL: '{nickName} 通过 {accountName} ,在 {mediaName} 留资：“{content}”',
  PRIVATE_LETTER: '私信',
  VIEW_CLUE_DETAIL: '查看线索详情',
  TELEPHONE: '电话',
  TRAFFIC_SOURCE: '流量来源',
  INTERACTION_RECORDS: '互动记录',
  INTERACTIVE_TIME: '互动时间',
  INTERACTION_ACCOUNT: '互动账号',
  CLUE_BASIC_INFO: '线索基础信息',
  USER_TIKTOK_ACCOUNT: '用户抖音号',
  USER_TIKTOK_NICKNAME: '用户抖音昵称',
  USER_NICKNAME: '用户账号昵称',
  USER_ACCOUNT: '用户账号',
  CUSTOMER_CITY: '客户城市',
  JOB_CANDIDATES: '应聘岗位',
  CITIES: '城市',
  CALL_STATUS: '通话状态',
  USER_INFO: '用户信息',

  AGE: '年龄',
  GENDER: '性别',
  WECHAT: '微信',
  BIRTHDAY: '生日',
  CLUE_SOURCE_INFO: '线索来源信息',
  IS_FUNDS: '是否留资',
  FUNDS_YES: '已留资',
  FUNDS_NO: '未留资',
  CITY_AREA: '地区',
  CLUE_INFO: '线索信息',
  LATEST_CLUE: '最新线索',
  COMMENT_COUNT: '意向评论次数',
  NUM: '序号',
  KS_ACCOUNT: '快手号',
  KS_NICKNAME: '快手昵称',
  COLLECTION_TIME: '收集时间',
  FILTER_RESULT: '清洗结果',
  CLUE_PREGRESS: '线索进度',
  TRANSFORM: '转化',

  //============系统组织结构==========
  CALL_NAME: '名称',
  CODE: '编码',
  ORG_TOTAL_TIP: ' 共{count}个{title}',
  DELETE_ORG_CONFIRM_TIP: '确认删除组织结构“{name}”?',
  DELETE_ORG_CONFIRM_SUB_TIP: '删除后不可找回',

  ORG_NAME_EXISTED: '组织机构名称已存在',
  INPUT_TEXT_VALIDATATION_TIP: '最多{count}个字符，支持英文、数字及符号',
  FULL_ADDRESS: '详细地址',

  //================= 矩阵运营 =================
  PICTURE_NOTE: '图文',
  PICTURE: '图片',
  LIST_WORKS: '作品列表',
  COVER: '封面',
  TITLE_OF_WORK: '作品标题',
  PUBLISH_TIME: '发布时间',
  ACTION_COMMENT: '评论',
  ACTION_VIEW_INTERACTIVE: '查看互动数据',
  TOTAL_NUMBER_OF_WORKS: '作品总量',
  STATIST_UNIT_TIAO: '条',
  OPERATIONAL_DATA: '运营数据',
  INTERACTIVE_EFFECT_OF_WORKS: '作品互动效果',
  WORK_INDICATORS: '作品指标',
  DRAFT: '草稿',
  PUBLISHED: '已发布',
  REMOVED: '已删除',
  REPLY: '回复',
  CLOSE_REPLY: '关闭回复',
  COMMENT_PLACEHOLDER_TIP: '有爱评论，说点儿好听的～',
  EMOTICON: '表情',
  SEND: '发送',
  SELECT_EMOTICON: '选择表情',
  SUMMARY_OF_COMMENTS: '评论汇总',
  COMMENT_TIME_ID: '评论时间/评论ID',
  CLASSIFICATION: '分类',
  HIDE_ALL_REPLIES: '收起全部回复',
  EXPAND_NUM_REPLIES: '展开{num}条回复',
  NO_MORE_DATA: '没有更多啦',
  AFTER_THE_VIDEO_IS_RELEASED: '视频发布后',
  PLAY_COUNT_TODAY: '当日播放量',
  SHARE_COUNT_TODAY: '当日分享',
  COMMENT_COUNT_TODAY: '当日评论',
  LIKE_COUNT_TODAY: '当日喜欢',
  FAV_COUNT_TODAY: '当日推荐',
  FOLLOW_COUNT_TODAY: '当日关注',

  PLAY_TODAY: '当日播放数',
  LIKE_TODAY: '当日点赞数',
  SHARE_TODAY: '当日分享数',
  COMMENT_TODAY: '当日评论数',
  DATE: '日期',
  LAST_NUM_DAYS: '近{num}天',
  LIKE_COUNT_TOTAL: '累计点赞数',
  SHARE_COUNT_TOTAL: '累计分享数',
  COMMENT_COUNT_TOTAL: '累计评论数',
  WORKS_COUNT_TOTAL: '累计作品总量',
  MATRIX_OPERATION_STATISTICS_TIP:
    '1.统计数据在账号首次授权后实时更新；2.趋势及明细数据需在用户首次授权应用后的第二天方可展示，且仅可提供三十天内创建视频的数据。',
  WORK_DETAILS: '作品详情',
  COMPLETION_RATE: '完播率',
  INDICATOR_DATA: '指标数据',
  LIKE_NEGATIVE_NUM_TIP: '点赞数为负数时，可能是由于用户取消点赞。',
  AVG_PLAY_DURATION: '平均播放时长',
  LIKE: '喜欢',
  RECOMMEND: '推荐',
  FELLOW: '关注',
  SHARE: '分享',
  PLAY: '播放',
  LIKE_COUNT_TOAL: '累计喜欢',
  RECOMMEND_COUNT_TOTAL: '累计推荐',
  COMMENT_COUNT_NUM_TOTAL: '累计评论',
  FOLLOW_COUNT_TOTAL: '累计关注',
  SHARE_COUNT_NUM_TOTAL: '累计分享',
  FAVORITE_DAILY_NUM: '当日收藏数',
  VIEWS_COUNT_TOTAL: '累计观看量',
  NOTE_COUNT_TOTAL: '累计笔记总量',
  VIDEO_COUNT_TOTAL: '累计视频总量',
  IMAGE_NOTE_COUNT_TOTAL: '累计图文总量',
  LIKED_TOTAL: '累计点赞量',
  COLLECTION_TOTAL: '累计收藏数',
  BARAGE_TOTAL: '累计弹幕数',
  INCREASING_NOTES_TOTAL: '累计笔记涨粉数',
  INCREASING_FUNS_TOTAL: '累计直接涨粉数',
  NOTE_TYPE: '笔记类型',
  VIEW_NUM: '观看量',
  COLLECTIONS_NUM: '收藏数',
  BULLET_COMMENTS_NUM: '弹幕数',
  INCREASING_FOLLOWERS: '直接涨粉数',
  ITEM_CLUE_COUNT: '视频留资',
  LIVE_CLUE_COUNT: '直播留资',
  OTHER_CLUE_COUNT: '其他留资',
  CAPTION_REQUIRED_TIP1: '作品简介，最多支持1000个文字输入',
  CAPTION_REQUIRED_TIP2: '作品标题和简介，最多支持1000个文字输入',

  //===========作品发布======
  STATUS_UNPUBLISHED: '待发布',
  STATUS_PUBLISHING: '发布中',
  STATUS_PUBLISHE_SUCCESS: '发布成功',
  STATUS_PUBLISHE_FAILURE: '发布失败',
  APPLICATION_INFO: '应用信息',
  LINK_URL: '链接地址',
  PLEASE_SELECT_APPLICATION: '请选择应用',
  MINI_APP: '小程序',
  SELECT_MINI_APP: '选择小程序',
  PLEASE_COPY_DY_APP_LINK: '请复制粘贴抖音小程序链接',
  PERMISSION_SETTING: '权限设置',
  ALLOW_SAVING_VIDEO: '允许保存视频',
  WORK_INTRODUCTION: '作品简介',
  WORK_INTRODUCTION_TYPE_INPUT_TIP: '添加作品简介，最多{num}个文字输入',
  VIDEO_MOUNTING: '视频挂载',
  VIDEO_MOUNTING_TIP: '若设置视频挂载，不同账号发布的相同内容挂载信息将为同一个。',
  SEND_ACCOUNT_NUM_TIP: '共计选择发送{num}个账号',
  POSITON_SETTING_TIP: '若设置当前地点，不同账号发布的相同内容地址信息将为同一个',
  VIDEO_TITLE: '视频标题',
  VIDEO_TITLE_TIP: '短标题会出现在搜索、话题、活动、地点、订阅号消息、发现页红点等场景。',
  VIDEO_TITLE_PLACEHOLDER: '概括主要内容，建议6-16字符',
  ORIGINAL: '原创',
  VIDEO_ORIGINAL_FIELD_TIP: '个人号不支持原创',
  VIDEO_ORIGINAL_FIELD_TEXT_DESCRIBE: '视频时长小于5秒以及图文内容，不能使用原创声明。',
  UPLOAD_VIDEO_FILE_TIP: '上传视频仅支持单个视频，格式为mp4、mov视频文件!',
  UPLOAD_VIDEO_DURATION: '需上传播放时长不能超过15分钟的视频',
  UPLOAD_VIDEO_MAX_SIZE_TIP: '上传视频文件大小不能超过128MB',
  CLICK_TO_UPLOAD: '点击上传',
  FILE_UPLOAD_REQUIRD_TIP:
    '支持mp4 、webm格式 上传视频文件大小超过128MB，且视频时长不能超过15分钟 推荐上传 16:9，分辨率为 720p (1280x720) 及以上的版视频。',
  FILE_UPLOAD_EXCEEDED: '超过最大上传数量',
  PUBLISH_SINGLE_IMG_REQUIRED: '单张图片最大支持 20M',
  PUBISH_IMG_WIDTH_HEIGHT_REQUIRED: '图文的宽高比建议不超过1:2',
  PUBISH_IMG_REQUIRED:
    '支持常用图片格式，暂不支持gif格式  单张图片最大支持 20M，每次最多上传 10 张图片  图文的宽高比建议不超过1:2',
  RESOUTION_TRTIO_REQUIRED_TIP: '分辨率过低，请上传分辨率大于324*432的图片',
  VERTICAL_SCREEN: '竖屏',
  LANDSCAPE_SCREEN: '横屏',
  COVER_IMG_SCREEN_REQUIRED: '支持jpeg、png、jpg格式 , 最大支持10M  小红书图文发布与视频号图文发布暂不支持封面上传',
  TASK_NAME: '任务名称',
  CONTENT_SETTING: '内容设置',
  PUBLISH_TIME_REQUIRED_TIP: '发布时间不能早于当前时间',
  CONTENT_TYPE: '内容类型',
  PUBLISH_VIDEO: '发布视频',
  PLEASE_UPLOAD_VIDEO: '请上传发布视频',
  UPLOAD_VIDEO_TIP:
    '带品牌 logo 或品牌水印的视频，会命中抖音的审核逻辑，有比较大的概率导致分享视频推荐降权处理/分享视频下架处理/分享账号被封禁处理。建议第三方应用自行处理好分享内容中的不合规水印。',
  UPLOAD_PIC_TEXT: '发布图文',
  PLEASE_UPLOAD_PIC_TEXT: '请上传发布图文',
  PUBLISH_COVER_IMG: '发布封面',
  PLATFORM_CONFIG: '平台配置',
  PUBLISH_TYPE_INSTANT_SEND: '立即发送',
  PUBLISH_TYPE_SCHEDULE_SEND: '定时发送',

  PUBISH_STATUS_DRAFT_TEXT: '草稿',
  PUBLISH_STATUS_PENDING_TEXT: '待执行',
  PUBLISH_STATUS_PROCESSING_TEXT: '执行中',
  PUBLISH_STATUS_PAUSED_TEXT: '已暂停',
  PUBLISH_STATUS_FINISHED_TEXT: '已完成',

  PUBLISH_TASK_STATUS_PREPARATION_TEXT: '待发布',
  PUBLISH_TASK_STATUS_PUBLISHING_TEXT: '发布中',
  PUBLISH_TASK_STATUS_SUCCESS_TEXT: '发布成功',
  PUBLISH_TASK_STATUS_FAILURE_TEXT: '发布失败',
  PUBLISH_TASK_STATUS_PAUSED_TEXT: '已暂停',

  PERMISSION_TYPE_PUBLISH_TEXT: '公开',
  PERMISSION_TYPE_PRIVATE_TEXT: '私密',
  PERMISSION_TYPE_FRIENDLY_TEXT: '好友可见',

  ALLOW: '允许',
  NOT_ALLOW: '不允许',
  NEW_PUBLISH_ITEM: '新建作品发布',
  VIEW_PUBLISH_ITEM: '查看作品发布',
  EDIT_PUBLISH_ITEM: '编辑作品发布',

  PUBLISH_RECORDS: '发布记录',
  TASK_STATUS: '任务状态',
  SUCCESS_COUNT: '发布成功数',
  FAILURE_COUNT: '发布失败数',
  ACTION_STOP_TASK: '暂停任务',
  ACTION_RESTART_TASK: '重启任务',
  STOP_TASK_CONFIRM_TIP: '是否暂停发布任务?',
  STOP_TASK_CONTENT_TIP: '确认后系统将暂停内容发布。',
  RESTART_TASK_CONFIRM_TIP: '是否重启任务?',
  RESTART_TASK_CONTENT_TIP: '确认后系统将重启任务发布。',
  CONFIRM_DELETE_TASK_CONFIRM_TIP: '是否删除发布任务？',
  CONFIRM_DELETE_TASK_CONTENT_TIP: '确认删除后将无法找回。',
  ERROR_LINK_FORMAT_TIP: '链接格式错误或参数不正确',
  CONFIRM_REPUBLISH_TIP: '是否重新发布?',
  CONFIRM_REPUBLISH_CONTENT_TIP: '确认后系统将立即执行内容发布',
  ACTION_BATCH_PUBLISH: '批量发布',
  PUBLISH_STATUS: '发布状态',
  PUBLISH_FINISH_TIME: '发布完成时间',
  PUBOISH_TITLE: '发布视频标题',

  //=============直播==============
  VIDEO_INFO: '视频信息',
  LP_SCREEN_GUIDE_ITEM_COUNT: '直播入口曝光次数',
  LP_SCREEN_GUIDE_ITEM_WATCH_COUNT: '引流直播间次数',
  LP_SCREEN_GUIDE_ITEM_ENTER_RATIO: '直播入口点击率',
  MALE: '男',
  FEMALE: '女',
  LIVE_DATA: '直播数据',
  LIVE_ACCOUNT: '直播账号',
  LIVE_END_TIME: '关播时间',
  LIVE_DURATION: '直播时长',
  ACTION_REPORT: '报告',
  ACTION_REPLAY: '回放',
  STATIST_UNIT_GE: '个',
  STATIST_UNIT_CI: '次',
  STATIST_UNIT_CHANG: '场',
  STATIST_UNIT_YUAN: '元',
  STATIST_UNIT_REN: '人',
  STATIST_UNIT_REN_CI: '人次',
  STATIST_UNIT_SEC: '秒',
  STATIST_UNIT_MIN: '分钟',
  STATIST_UNIT_HOUR: '小时',
  STATIST_UNIT_DAY: '天',
  STATIST_UNIT_WEEK: '周',
  STATIST_UNIT_MONTH: '个月',
  STATIST_UNIT_YEAR: '年',

  LIVE_STATIST_ACCOUNT_NUM: '开播账号数',
  LIVE_STATIST_ACCOUNT_NUM_TIP: '汇总筛选条件下有开播的账号数量',
  LIVE_STATIST_LIVE_NUM: '直播场次',
  LIVE_STATIST_LIVE_TOTAL_NUM: '累计直播场次',
  LIVE_STATIST_LIVE_NUM_TIP: '汇总筛选条件下账号发起直播场次累计数',
  LIVE_STATIST_LIVE_DURATION: '累计直播时长',
  LIVE_STATIST_LIVE_DURATION_TIP: '汇总筛选条件下账号发起直播的累计时长',
  LIVE_STATIST_VIEW_NUM: '累计观看人数',
  LIVE_STATIST_VIEW_NUM_TIP: '汇总筛选条件下账号发起直播的累计观看人数，不同场次之间的人数不去重',
  LIVE_STATIST_DURATION_AVG_TIP: '筛选条件下账号发起直播，直播间观看时长（累计）/进入直播间用户数（累计）',
  LIVE_STATIST_COMMENT_NUM: '累计评论次数',
  LIVE_STATIST_COMMENT_NUM_TIP: '汇总筛选条件下账号发起直播的累计评论次数',
  LIVE_STATIST_CLUE_NUM: '累计线索量',
  LIVE_STATIST_CLUE_NUM_TIP: '汇总筛选条件下账号发起直播的累计线索次数',
  INTERACTIVE_EFFECT: '互动效果',
  DATA_INDICATORS: '数据指标',
  LIVE_INDICATOR_WATCH_UV: '观看人数',
  LIVE_INDICATOR_UV: '累计观看人数',
  LIVE_INDICATOR_UV_TIP: '整场直播的场观人数',
  LIVE_INDICATOR_MAX_WATCH_UV: '最高在线人数',
  LIVE_INDICATOR_MAX_WATCH_UV_TIP: '整场直播的最高在线人数',
  LIVE_INDICATOR_AVG_WATCH_DURATION_TIP: '所有人观看时长之和 / 场观',
  LIVE_INDICATOR_FOLLOW_UV: '涨粉量',
  LIVE_INDICATOR_FOLLOW_UV_TIP: '直播期间获得的粉丝数，点击左上角关注人数',
  LIVE_INDICATOR_COMMENT_UV: '评论人数',
  LIVE_INDICATOR_COMMENT_UV_TIP: '有评论动作的人数，去重统计，一人多次评论算一次',
  LIVE_INDICATOR_MSG_CONVERSATION_COUNT: '私信人数',
  LIVE_INDICATOR_MSG_CONVERSATION_COUNT_TIP:
    '本场直播期间产生私信会话的人数(包含用户没有开口，商家设置了主动问候语的情况)',
  LIVE_INDICATOR_CLUE_UV: '全场景线索人数',
  LIVE_INDICATOR_CLUE_UV_TIP: '单场直播时间内 各渠道(小风车卡片提交， 私信留资，智能电话留资)产生的线索',
  LIVE_INDICATOR_WATCH_GT_MIN_COUNT: '>1分钟观看人次',
  LIVE_INDICATOR_WATCH_GT_MIN_COUNT_TIP: '单次观看时长大于1分钟的人次 (非人数)',
  LIVE_INDICATOR_USER_REALTIME: '实时在线人数',
  LIVE_INDICATOR_USER_REALTIME_TIP: '直播期间每分钟实时人数。下播后该值为0',
  LIVE_INDICATOR_FANS_WATCH_UV: '粉丝数',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT: '卡片点击次数',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_COUNT_TIP:
    '仅统计了卡片(抖音APP直播间右下角讲解卡 和 小风车里的卡片)的点击, 并且是次数(非人数)',
  LIVE_INDICATOR_SHOW_COUNT: '曝光次数',
  LIVE_INDICATOR_SHOW_COUNT_TIP: '直播间曝光所触达的次数，不去重统计',
  LIVE_INDICATOR_ENTER_RATIO: '曝光进入率',
  LIVE_INDICATOR_ENTER_RATIO_TIP: '累计观看人数 / 曝光人数',
  LIVE_INDICATOR_ICON_CLICK_COUNT: '小风车点击次数',
  LIVE_INDICATOR_ICON_CLICK_COUNT_TIP: '仅统计了小风车的点击(不含讲解卡), 并且是次数(非人数)',
  LIVE_INDICATOR_ICON_CLICK_RATE: '小风车点击率',
  LIVE_INDICATOR_ICON_CLICK_RATE_TIP: '小风车点击率 = 小风车点击次数 / 观看次数',
  LIVE_INDICATOR_INTERACTION_RATIO: '互动率',
  LIVE_INDICATOR_INTERACTION_RATIO_TIP: '互动人数 / 累计观看人数',
  LIVE_INDICATOR_BIZ_WECHAT_COST: '加微信成本',
  LIVE_INDICATOR_BIZ_WECHAT_COST_TIP: '加微信成本 = 广告消耗 / 企业微信添加数',
  LIVE_INDICATOR_FORM_COUNT: '表单提交数',
  LIVE_INDICATOR_FORM_COUNT_TIP: '巨量广告平台直播大屏同名指标，仅限于挂载小雪花或者外链的情况时使用。',
  LIVE_INDICATOR_WATCH_COUNT: '观看次数',
  LIVE_INDICATOR_WATCH_COUNT_TIP: '整场直播的观看次数，不去重统计',
  LIVE_INDICATOR_FANS_WATCH_RATIO: '粉丝占比',
  LIVE_INDICATOR_FANS_WATCH_RATIO_TIP: '观看为粉丝的人数 / 累计观看人数',
  LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM: '平均在线人数',
  LIVE_INDICATOR_AVG_ONLINE_UV_BY_ROOM_TIP: '每分钟最高在线人数相加 / 直播分钟数',
  LIVE_INDICATOR_UV_WITH_PREVIEW: '看过',
  LIVE_INDICATOR_UV_WITH_PREVIEW_TIP:
    '对应抖音APP下播页的那个看过人数，等于进入直播间的人加上推荐流预览观看直播间超过3秒但没有进入直播间的人',
  LIVE_INDICATOR_SHARE_COUNT: '分享次数',
  LIVE_INDICATOR_SHARE_COUNT_TIP: '有分享行为的次数，不去重统计',
  LIVE_INDICATOR_SHARE_UV: '分享人数',
  LIVE_INDICATOR_SHARE_UV_TIP: '有分享行为的人数，去重统计，一人多次分享算一次',
  LIVE_INDICATOR_SHARE_RATIO: '分享率',
  LIVE_INDICATOR_SHARE_RATIO_TIP: '分享人数 / 累计观看人数',
  LIVE_INDICATOR_LIKE_UV: '点赞人数',
  LIVE_INDICATOR_LIKE_UV_TIP: '有点赞行为的人数，去重统计，一人多次点赞算一次',
  LIVE_INDICATOR_LIKE_COUNT: '点赞次数',
  LIVE_INDICATOR_LIKE_COUNT_TIP: '有点赞行为的次数，不去重统计',
  LIVE_INDICATOR_LIKE_RATIO: '点赞率',
  LIVE_INDICATOR_LIKE_RATIO_TIP: '点赞人数 / 累计观看人数',
  LIVE_INDICATOR_COMMENT_COUNT: '评论次数',
  LIVE_INDICATOR_COMMENT_COUNT_TIP: '有评论动作的次数，不去重统计',
  LIVE_INDICATOR_COMMENT_RATIO: '评论率',
  LIVE_INDICATOR_COMMENT_RATIO_TIP: '评论人数 / 累计观看人数',
  LIVE_INDICATOR_INTERACTION_COUNT: '互动次数',
  LIVE_INDICATOR_INTERACTION_COUNT_TIP: '有 点赞，评论，分享 任意动作的次数，不去重统计',
  LIVE_INDICATOR_INTERACTION_UV_COUNT: '互动人数',
  LIVE_INDICATOR_INTERACTION_UV_COUNT_TIP:
    '有 点赞，评论，分享 任意动作的人数，去重统计，一人多次点赞，评论，分享 算一次',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV: '加粉丝团人数',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_TIP: '加粉丝团人数，去重统计',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_RATIO: '加团率',
  LIVE_INDICATOR_FANS_CLUB_JOIN_UV_RATIO_TIP: '加粉丝团人数 / 累计观看人数',
  LIVE_INDICATOR_FOLLOW_RATIO: '关注率',
  LIVE_INDICATOR_FOLLOW_RATIO_TIP: '涨粉量/累计观看人数',
  LIVE_INDICATOR_CLUE_CONVERT_RATIO: '线索转化率',
  LIVE_INDICATOR_CLUE_CONVERT_RATIO_TIP: '全场景线索人数/累计观看人数',
  LIVE_INDICATOR_FORM_COST: '表单成本',
  LIVE_INDICATOR_FORM_COST_TIP: '表单成本 = 广告消耗 / 表单提交数',
  LIVE_INDICATOR_LONGTERM_MSG_CLUE_UV: '私信长效转化',
  LIVE_INDICATOR_LONGTERM_MSG_CLUE_UV_TIP:
    '直播期间发生了私信会话但没有留资，下播后1小时内留资人数。2023年05月10号后才有数据',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_RATE: '卡片点击率',
  LIVE_INDICATOR_BUSINESS_CARD_CLICK_RATE_TIP: '卡片点击率 = 卡片击次数 / 卡片曝光次数',
  LIVE_INDICATOR_BIZ_WECHAT_ADD_COUNT: '企业微信添加数',
  LIVE_INDICATOR_BIZ_WECHAT_ADD_COUNT_TIP: '巨量广告平台直播大屏同名指标，仅限于挂载小雪花或者外链的情况时使用',
  LIVE_INDICATOR_CLUE_COST: '线索成本',
  LIVE_INDICATOR_CLUE_COST_TIP: '直播间广告消耗金额/直播间线索总量',
  LIVE_INDICATOR_STAT_COST: '广告消耗',
  LIVE_INDICATOR_STAT_COST_TIP: '与该直播间关联的投广消耗',
  LIVE_COMMENT_NUMBER: '共 {count} 个弹幕评论',
  LIVE_INDICATOR_TIP: '趋势分析至少展示1个维度，最多展示3个维度的数据',
  LIVE_STATIST_AUDIENCE_TRAFFIC_SOURCE: '观众流量来源',
  LIVE_STATIST_AGE: '{age}岁',
  LIVE_STATIST_AGE_ABOUT: '{age}岁以上',
  LIVE_STATIST_AUDIENCE_DATA: '观众数据',
  LIVE_STATIST_INTERACTIVE_DATA: '互动数据',
  LIVE_STATIST_CONVERT_DATA: '转化数据',
  SATREAMING_SHORT_VIDEO: '引流短视频',
  TRAFFIC_CONVERSION: '流量转化',
  USER_PROFILE_ANALYSIS: '用户画像分析',
  RESIDENCE_DISTRIBUTION: '停留分布',
  TRAFFIC_ANALYSIS: '流量分析',
  CITY_TOP_10: '城市排名Top10',
  PROVINCE_TOP_10: '省份排名Top10',
  LIVE_DETAIL_PLAY_TIP: '每个直播间只展示非自见或者不涉及官方规则的弹幕',
  LIVE_DETAIL_TAB_COMMENT: '评论',
  LIVE_DETAIL_TAB_HIGH_LIGHT: '高光时刻',
  LIVE_INDICATOR_GROUP_POPULARITY: '人气指标',
  LIVE_INDICATOR_GROUP_INTERACTIVE: '互动指标',
  LIVE_INDICATOR_GROUP_CONVERT: '转化指标',
  LIVE_INDICATOR_GROUP_OTHER: '其他指标',
  REAL_TIME_DYNAMICS: '实时动态',
  TIME_MINUTE_TPL: '{time}分钟',
  TIME_HOUR_TPL: '{time}小时',
  LIVE_CLICK_PLAY_HIGH_LIGHT: '点击播放高光时刻',
  LIVE_DETAIL: '直播详情',
  SUMMARY_OF_BARRAGES: '弹幕汇总',
  LIVE_VIEW_USER_COUNT: '累积观看人数',
  CLUE_ANALYSIS: '线索分析',
  CLUE_TRAFFIC_SOURCCE: '线索流量来源',
  AREA_DISTRIBUTION: '地域分布',
  CITY: '市',
  SEGMENTED_SCENE_ANALYSIS: '细分场景分析',
  LIVE_ACCUMULATED_VIEWER_TOTAL: '累计观众数',
  PEAK_ONLINE_USER: '在线人数峰值',
  CLUE_COLLECTION_NUM: '线索收集量',
  GIFT_RECEIVED_NUM: '收礼数',
  GIFT_COLLECTION_FLOW_NUM: '收礼流水',
  CLUE_VOMUME_MOUNTING_RATE: '线索量挂载率',
  ADD_FOLLOW: '新增关注',
  TOTAL_HEAT: '总热度',
  FAN_DISTRIBUTION: '粉丝分布',
  CHANNEL_DISTRIBUTION: '渠道分布',
  REGION_AREA_TOP_5: '地域分布Top5',
  SHARE_LIVE_ROOM_USER_COUNT: '分享直播间人数',
  SHARE_LIVE_ROOM_TIME_COUNT: '分享直播间次数',
  GIFT_GIVE_USER_COUNT: '送礼人数',
  GIFT_GIVE_HEAT: '送礼热度',
  ENTER_ROOM_USER_COUNT: '进房人数',
  LEAVE_ROOM_USER_COUNT: '离开人数',
  DATA_OVERVIEW: '数据总览',
  TRAFFIC_AND_SOURCE_TREND: '流量&来源趋势',
  TRAFFIC_TREND: '流量趋势',
  INTERACTIVE_TREND: '互动趋势',
  CUMULATIVE_WATCH_COUNT: '累计观看人数统计',
  ADD_TRACKING_STATISTICS: '新增关注统计',
  LIVE_COVER_TITLE: '直播标题',

  //================工作台============
  PLAY_ANALYSIS: '播放分析',
  CONVERT_ANALYSIS: '转化分析',
  LIVE_PLAY_TOP10: '直播排名Top10',
  VIDEO_PLAY_TOP10: '直播排名Top10',
  FANS_SORT_TOP10: '粉丝排名Top10',
  LEADS_RORT_TOP10: '线索量排名Top10',
  PLAY_COUNT: '播放次数',
  TIME: '时间',
  ASC: '升序',
  DESC: '降序',
  BY_NAME: '按{name}',
  CONTENT_ANALYSIS: '内容分析',
  LIVE_OVERVIEW: '直播概览',
  LIVE_TREND_ANAYLISIS: '直播趋势分析',
  LIVE_TREND: '直播趋势',
  FANS_ANALYSIS: '粉丝分析',
  RANK_STATISTICS: '排名统计',
  VIDEO_OVERVIEW: '视频概览',
  VIDEO_TREND_ANAYLISIS: '视频趋势分析',
  TRANSFORMATION_ANALYSIS: '转化分析',
  VIDEO_CONVERSION: '视频转化',
  LIVE_CONVERSION: '直播转化',
  PEOPLE_NUM: '{num}人',
  PEOPLE: '人',
  NUM_CLOCK: '{num}点',
  NUM_AGE: '{num}岁',
  OVER_NUM_AGE: '{num}+岁',
  FANS_COUNT: '粉丝总数',
  UNKNOWN: '未知',

  LP_SCREEN_CLUE_UV: '全场景线索人数',
  LP_SCREEN_LIVE_CARD_CLICK_UV: '线索工具点击人数',
  LP_SCREEN_LIVE_SHOW_UV: '直播间曝光人数',
  LP_SCREEN_LIVE_WATCH_UV: '直播进入人数',

  LIVE_TOTAL: '直播场次',
  LP_SCREEN_LIVE_COMMENT_COUNT: '评论次数',
  LP_SCREEN_LIVE_LIKE_COUNT: '点赞次数',
  LP_SCREEN_LIVE_SHARE_COUNT: '分享次数',
  LP_SCREEN_LIVE_VIEW_UV: '观看人数',

  INTERACTION_TOTAL: '互动总次数',
  ITEM_CLUE_TOTAL: '视频留资次数',
  PLATY_TOTAL: '累计播放量',

  COMMENT_TOTAL: '评论数',
  VIDEO_ITEM_TOTAL: '视频发布',
  LIKE_TOTAL: '点赞数',
  PLAY_TOTAL: '播放量',
  SHARE_TOTAL: '分享数',
  OPERATION_ACCOUNT_TOTAL: '运营账号总数',
  EXCEPTION_ACCOUNT: '异常账号',
  FANS_TOTAL: '粉丝总数',
  CLUE_TOTAL: '线索总量',
  LIVT_TOTAL_TIME: '直播总时长',
  TIMES_EVERY_WEEK: '次/周',
  LIVE_ACCUMULATED_WATCH_TOTAL: '直播累计观看人数',
  AVG_WATCH_TIME: '人均观看时长',
  AVG_WATCH_TIME_SECOND: '人均观看时长(秒)',

  LIVE_FREQUENCY: '直播频次',
  VIDEO_PUBLISH_TOTAL: '视频发布总量',
  PALY_TOTAL: '累积播放量',
  PUBLISH_FREQUENCY: '发布频次',
  LIVE_WATCH_COUNT: '观看次数',
  LIVE_LIKE_COUNT: '点赞次数',
  LIVE_COMMENT_COUNT: '评论次数',
  LIVE_COMMENT_UV: '评论人数',
  LIVE_SHARE_COUNT: '分享次数',
  THEATER_COMMAND: '战区',
  STATIST_UNIT_JIA: '家',
  VIDEO_TOTAL: '视频总量',
  STAY_ANALYSIS: '停留分析',
  INTERACTIVE_ANALYSIS: '互动分析',
  LIKE_CLICK: '点赞',
  RISING_FOLLOWERS_ANALYSIS: '涨粉分析',
  NEW_FUNS_COUNT: '新增粉丝量',
  RETENTION_ANALYSIS: '留资分析',
  RETNETION_AMOUNT: '留资量',
  WORK_SUMMARY_ANALYSIS: '作品分析',
  LIVE_SUMMARY_ANALYSIS: '直播分析',

  //=============系统授权===============
  ACCOUNT_STATUS_INIT: '初始化中',
  ACCOUNT_STATUS_EXPIRED: '二维码已过期',
  ACCOUNT_STATUS_CONFIRMED: '已扫码确认',
  ACCOUNT_STATUS_REFUSED: '授权登录已经拒绝',
  ACCOUNT_STATUS_NEW: '等待扫码中',
  ACCOUNT_STATUS_ERROR: '发生错误',
  ACCOUNT_STATUS_SUCCESS: '授权成功',
  ACCOUNT_STATUS_FAILURE: '授权失败',
  ACCOUNT_STATUS_EXCEPTION: '授权异常',
  ACCOUNT_STATUS_SCANNED: '已扫码',
  AUTHORIZATION_SUCCESS: '授权成功',
  SCAN_QR_ERROR: '扫码异常',
  AUTHORIZATION_STATUS: '授权状态',
  LGOIN_AUTH_STATUS: '登录状态',
  CREATOR_AUTH_STATUS: '创作者授权状态',
  CREATOR_AUTH_STARTTIME: '创作者授权开始时间',
  CREATOR_AUTH_ENDTIME: '创作者授权结束时间',
  ACCOUNT_TYPE: '账号类型',
  DOWNLOAD_AUTH_LINK: '下载授权链接',
  DOWNLOAD_AUTH_LINK_TIP: '抖音平台授权有效期为195天，登录授权有效期为60天，到期后需要重新授权',
  AUTHORIZATION_PLATFORM: '授权平台',
  PLATFORM_AUTHORIZATION: '平台授权',
  DELER_CODE: '经销商编码',
  SYNC_BI_DATA: 'BI报表更新',
  SYNC_CLUE_RULES: '线索规则更新',
  ACCOUNT_AUTHORIZATION: '账号授权',
  AUTHORIZATION_EXPIRED: '授权已过期',
  ADD_ACCOUNT: '添加账号',
  UN_AUTHORED_ACCOUNT: '未授权账号 {num}',
  ANONMOUS_ACCOUNT_AUTHORIZATION_TIP: '当前正在运行 “{businessUnionName}”  账号授权',
  NEW_AUTHORIZATION_ACCOUNT: '新建授权账号',
  ACCOUNT_AUTHORIZED_TIP:
    '授权后，视频数据将拉取近30天内的视频相关数据；会话将拉取近一年内的相关数据；直播数据将拉取近60天的直播数据。',
  UNAUTHORIZATION_TIP: '请先进行平台授权',
  UNLOGIIN_AUTHORIZATION: '请先进行登录授权',
  AUTHORIZATION_ORG_UNSELECTED_TIP: '若未选择所属组织默认为品牌抖音号',
  PHONENUMBER_PLACEHOLDER: '请输入正确的手机号码',
  POI_POSITION: '地理位置',
  REMARK: '备注',
  OPERATOR_PHONE_NUM: '运营者手机',
  OPEN_QR_CODE_POP_UP_TIP: '点击打开二维码弹窗进行',
  AUTHORIZATION: '授权',
  REAUTHORIZATION: '重新授权',
  LOGIN_AUTHORIZATION: '登录授权',
  CREATOR_AUTHORIZATION: '创作者授权',
  PLEASE_AGREE_AUTHORIZE: '请先同意授权',
  PLEASE_PLATFORM_AUTHORIZE: '请先进行平台授权',
  CONFIRM_REAUTHORIZATION: '确认重新授权？',
  AFTER_REAUTHORIZATION_TIP: '重新授权后将会清空原授权状态。',
  AUTHORIZATION_TIME: '授权时间',
  CHECK_INDICATE_AGREEMENT: '勾选即代表同意',
  APPLICAITON_LICENSE_AGREEMENT: '应用许可协议',
  ACCOUNT_AUTHORIZATION_CANCELED_TIP: '该账号的授权已被管理者在抖音后台取消，请点击重新授权',
  ACCOUNT_AUTHIRIZATION_EXPIRED_TIP: '该账号的授权已过期，请点击重新授权',
  CLICK_HERE: '点击此处可',
  GOTO_PLATFORM_AUTHORIZATION_PAGE_TIP: '点击跳转至抖音账号授权页进行',
  OBTAINED_FOLLOWING_PERMISSION: '已获得以下权限',
  WILL_OBTAINE_FOLLOWING_PERMISSION: '将获得以下权限',
  QUICK_ANS_SECURE_LOGIN: '快捷安全登录',
  RETRACT_UNOBTAINED_FOLLOWING_PERMISSION: '收起未获得权限',
  EXPEND_UNOBTAINED_FOLLOWING_PERMISSION: '展开未获得权限',
  REQUIED_ITEM_CHECKED_TIP: '请检查必填项及相关项目是否正确输入！',
  PHONE_NUMBER: '手机号码',
  DELER_CODE_REQUIRED_TIP: '请输入经销商编码',
  ENTERPRISE_ACCOUNT: '企业号',
  PERSONAL_ACCOUNT: '个人号',
  CLOSE: '关闭',
  TRY_REFRESH_QRCODE_TIP: '刷新二维码异常,请重试',
  CLICK_TO_REAUTHORIZE: '点击重新发起授权',
  CLICK_LOAD_QRCODE: '点击加载二维码',
  CLICK_TO_RELOAD: '点击重新加载',
  COPY_AUTHORIZATION_INFO: '复制授权信息',
  COPY_TEXT_TO_CLIPBOARD: '文本已复制到剪贴板！',
  COPY_TEXT_FAILURE: '复制文本失败',
  AUTH_LINK: '授权链接',
  LOAD_MROE: '加载更多',
  SELECT_POSITION: '请选择位置',
  CLEAR: '清除',
  SELECT_AFTER_INPUT_KEYWORD: '请输入关键词后再选择',
  PLEASE_SET_POSIITON: '请设置位置',
  POSITION: '位置',
  REGION: '区域',
  PLATFROM_QR_AUTH_TIP1: '获得你的手机号码',
  PLATFROM_QR_AUTH_TIP2: '获取你的{platform}头像、昵称',
  PLATFROM_QR_AUTH_TIP3: '往你的{platform}账号内发布公开作品（授权后无需你操作发布）',
  PLATFROM_QR_AUTH_TIP4: '获取你发布的{platform}视频数据（播放数、点赞数、评论数、分享数）',
  AUTHORIZATION_INFO: '授权信息',
  ORG_CODE: '组织编码',
  VIEW_AUTHORIZED_ACCOUNT: '查看授权账号',
  LOGIN_AUTH_START_TIME: '登录授权开始时间',
  LOGIN_AUTH_END_TIME: '登录授权结束时间',
  PLATFORM_AUTH_END_TIME: '平台授权开始时间',
  OPEN_APP_AND_SCAN_QR: '使用 {platform}APP ，打开 [首页 - 更多 - 扫一扫]',

  //============系统语义分类========
  KEYWORD_CHANGED_TIP: '每次新增、修改或重启语义分类会在第二天0点正式生效',
  NO_KEYWORD_CLASSFICATION: '暂无关键词分类',
  KEYWORD_TOTAL_TIP: '共{count}个关键词分类',
  CONVERSATION: '会话',
  COMMENT: '评论',
  BARRAGE: '弹幕',
  CLASSIFICATION_NAME: '分类名称',
  SEMANTIC_CLASSIFICATION_TYPE: '所属语义分类',
  KEYWORD: '关键词',
  INCLUDE_KEYWORDS: '关联关键词',
  EXCLUDED_KEYWORDS: '屏蔽关键词',
  CONFIRM_DELETE_KEYWORD_TIP: '确认删除分类{name}及关键词?',
  CONFIRM_DELETE_KEYWORD_SUB_TIP: '删除后，内容中即使包含屏蔽关键词也不贴语义分类，也不会做归纳统计',
  CONFIRM_ENABLE_KEYWORD_TIP: '确认启用分类{name}及关键词?',
  CONFIRM_ENABLE_KEYWORD_SUB_TIP: '启用后，语义分类会在明天0点正式生效',
  CONFIRM_DISABLE_KEYWORD_TIP: '确认禁用分类{name}及关键词?',
  CONFIRM_DISABLE_KEYWORD_SUB_TIP: '禁用后，内容中即使包含屏蔽关键词也不贴语义分类，也不会做归纳统计',
  CONFIG_KEYWORD_RULE_TIP: '关联关键词里的关键词或动态规则至少配置一个',
  CONFIG_KEYWORD_RULE_REPEATION_TIP: '关联关键词或者屏蔽关键词之间存在重复关键词 “{keyword}”',
  PLEASE_ENTER_CATEGROY_NAME: '请输入分类名称',
  CATEGROY_NAME_VERIFICATION_TIP: '分类名称只能包含中英文数字',
  CATEGROY_EXISTED: '分类已存在',
  AFFECT: '作用',
  RULE_CONFIG: '规则配置',
  INCLUDE_KEYWORDS_CONFIG_SUB_TIP: '内容中包含关联关键词则贴上语义分类',
  EXCLUDE_KEYWORDS_CONFIG_SUB_TIP: '内容中包含屏蔽关键词则不贴语义分类',
  KEYWROD_VERIFICATION_RULE_TIP:
    '多关键词以回车键换行分隔，最多可输入100个关键词，单个关键词最多50个字符，仅支持中英文、数字，不区分大小写',

  KEYWROD_VERIFICATION_RULES_LENGH_TIP: '最多添加100个规则',
  SINGLE_KEYWROD_RULE_TEXT_LENGH_TIP: '单条规则的长度最多50个字符',
  KEYWROD_RULE_TEXT_FORMATTER_TIP: '仅支持中英文、数字，不区分大小写',

  KEYWROD_VERIFICATION_RULE_PLACEHOLDER: ' 一行一个，支持中英文、数字及符号',
  DYNAMIC_RULE: '动态规则',
  CONFIG_RULE_REQUIRED_TIP: '至少配置一条规则',
  CONFIG_RULE_ITEM_REQUIRED: '规则项需必填',
  SYSTEM_RULE: '系统规则',
  CUSTOM_RULE: '自定义规则',
  CUSTOM_RULE_NAME: '自定义规则名称',
  PLEASE_SELECTED_RULE: '请选择规则',
  PLEASE_ENTER_RULE_NAME: '请输入自定义规则名称',
  PLEASE_ENTER_REGULAR_EXPRESSION: '请输入正则表达式',
  REGULAR_EXPRESSION_VERIFICATION: '正则表达式验证',
  REGULAR_EXPRESSION: '正则表达式',
  PLEASE_ENTER_RIGHT_REGULAR_EXPRESSION: '请输入正确的正则表达式',
  CLASSIFICATION_AND_DATA_FILLED_TIP: '分类名称和样例数据需要都填写',
  SAMPLE_DATA: '样例数据',
  PLEASE_ENTER_SAMPEL_DATA: '请输入样例数据',
  VERIFICATION_RESULT: '验证结果',
  CLICK_TO_VIEIFY: '点击验证',
  CLICK_TO_REVALIDATION: '点击重新验证',
  PASS: '通过',
  NOT_PASS: '未通过',
  RULE_TYPE: '规则类型',
  RULE_NAME: '规则名称',
  CREATE_KEYWORD: '新建语义分类',
  VEIW_KEYWORD: '查看语义分类',
  EDIT_KEYWORD: '编辑语义分类',

  //==============权限管理==============
  RESOURCE: '资源',
  ATTRIBUTE_SCOPE: '属性范围',
  OPERATE_TYPE: '操作类型',
  ORG_STRUCTURE: '组织架构',
  ACTION_ADD_USER: '添加用户',
  ACTION_BATCH_REMOVE: '批量移除',
  RANDOM: '随机',
  SELECT_NAME: '选择{name}',
  ROLE: '角色',
  USER_GROUP: '用户组',
  ROLE_NAME: '角色名称',
  FUNCTION_PERMISSION: '功能权限',
  SUPPORT_CN_EN_NUMBER_UNLINE_TEXT_TIP: '支持中英文、数字及下划线',
  SUPPORT_EN_NUMBER_UNLINE_TEXT_TIP: '支持中英文、数字及下划线',
  COUNT_CN_EN_NUMBER_UNLINE_TEXT_REQUIRD_TIP: '最多{count}个字符，支持中英文、数字及下划线',
  COUNT_CN_EN_NUMBER_UNLINE_SYMBOL_TEXT_REQUIRD_TIP: '最多{count}个字符，支持中英文、数字及符号',
  NAME_EXISTED: '{name}已经存在',
  FUNCTION_PERMISSION_SET_TIP: '操作权限对应的数据范围，请在用户组中设置',
  PERMISSION_CONFIG: '权限配置',
  USER_COUNT: '用户数',
  USERGROUP_NAME: '用户组名称',
  BIND_USERGOUP: '关联用户组',
  OPERATION_PERMISSION: '操作权限',
  CONFIRM_DELETE_USER_CONTENT_TIP: '删除用户将无法找回并且无法登录系统',
  CONFIRM_DELETE_ROLE_CONTENT_TIP: '删除角色将无法找回并且用户权限将被移除',
  CONFIRM_DELETE_USERGROUP_CONTENT_TIP: '删除用户组将移除相关用户的权限',

  USERNAME: '用户名',
  EMAIL: '邮箱',
  BIND_ROLE: '关联角色',
  DATA_PERMISSION: '数据权限',
  CURRENT_GROUP: '所在用户组',
  OVERVIEW_PERMISSION: '数据权限一览',
  CONFIRM_DISABLE_USER: '确认是否禁用用户?',
  CONFIRM_DISABLE_USER_CONTENT_TIP: '禁用用户将无法登录系统',
  GROUP_USERS: '组内用户',
  USER_CONFIG: '用户配置',
  ACTION_SAVE_AND_CONFIG_PERMISSION: '保存并配置权限',
  CONFIG_PERMISSION: '配置权限',
  CONFIG_USERGROUPS: '配置用户组',
  PLEASE_CONFIG_NAME: '请配置{name}',
  CONFIG_PERMISSION_VALIDATE_TIP: '数据权限存在必填项目未填写',
  OPERATOR_SYMBOL: '匹配符',
  EQUAL: '等于',
  NOT_EQUAL: '不等于',
  MODULE: '模块',
  RESOURCE_OBJ: '资源对象',
  ROLE_CONFIG_ATTR_MISSING: '当前数据权限角色对象已被删除，请重新配置。',
  NO_PERMISSION_VISIT: '无权限访问',
  PLEASE_CONTACT_ADMINISTRATOR_TO_OBTAIN_PERMISSION: '请联系管理员获取权限',
};
